@import '../../styles/variables';

.singleDivider {
  height: 0.1rem;
  background-color: $mobileInputBorderColor;
  width: 100%;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .text {
    margin: 0 1rem;
    line-height: 1.7rem;
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap;
  }
}
