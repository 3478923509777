@use 'sass:map';
@import '../../styles/styles.scss';

.body {
  &.white {
    background: $white;
  }

  &.electric-blue {
    background: $electric-blue;
  }

  &.sea-green {
    background: $sea-green;
  }

  &.soft-lilac {
    background: $soft-lilac;
  }

  .container {
    max-width: 144rem;
    margin: 0 auto;
  }
}
