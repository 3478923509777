@import '../../styles/breakpoints';
@import '../../styles/variables';

strong {
  font-weight: bold;
}

.illustration-icon {
  max-width: 18rem;
}

.opt-in-subtitle {
  display: flex;
  margin-top: min(1rem, 10vw);
  flex-direction: column-reverse;

  @include respondTo(tablet) {
    flex-direction: row;
    margin-top: 4rem;
    font-size: min(17rem, 15vw);
  }

  > .subtitle {
    width: 100%;
  }

  > .right-subtitle {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    cursor: pointer;
    flex-wrap: wrap;
    justify-content: space-between;
    span {
      white-space: nowrap;
    }

    @include respondTo(tablet) {
      margin-top: 0rem;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    > * {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;

      &:not(:last-child) {
        margin-right: 4rem;
      }

      @include respondTo(tablet) {
        transform: scale(1);
      }

      > span {
        font-size: 2rem;
        font-weight: bold;
        margin-right: 1rem;

        @include respondTo(tablet) {
          font-size: 2.4rem;
          margin-right: 2rem;
        }
      }

      > img {
        width: 3rem;
        @include respondTo(tablet) {
          width: 5rem;
        }
      }
    }
  }
}

.opt-in {
  margin-bottom: 6rem;
  @include respondTo(tablet) {
    margin-bottom: 10rem;
  }
  .out-out-description {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .opt-in-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img.bottom-stars {
      position: absolute;
      top: 0;
      right: -2rem;

      @include respondTo(tablet) {
        width: min(12.5rem, 9vw);
        transform: translateY(-100%);
      }
    }

    @include respondTo(tablet) {
      flex-direction: row;
      > div {
        margin-left: 5rem;
      }
    }

    .image-card {
      max-width: 100%;
      @include respondTo(tablet) {
        width: min(67rem, 49vw);
      }
    }

    .opt-in-header {
      font-weight: bold;
      font-size: 4rem;
      margin-bottom: 4rem;
    }

    button {
      width: auto;
      margin-top: 4rem;
    }
  }

  .opt-in-bottom-container {
    margin-top: 10rem;
    margin-bottom: 25rem;
    display: flex;
    flex-direction: row;
    position: relative;

    .out-out-description {
      margin-bottom: 4rem;
    }

    @include respondTo(tablet) {
      > div {
        width: 60%;
        margin-right: 5rem;
      }
    }

    img {
      position: absolute;
      &.colorful-stars {
        width: 6rem;
        left: 1.5rem;
        bottom: -3rem;
      }

      &.women-with-glasses {
        width: 36rem;
        left: -3.5rem;
        bottom: -26rem;
      }
    }

    @include respondTo(tablet) {
      img {
        position: absolute;
        &.colorful-stars {
          width: 5%;
          left: -2%;
          top: -28%;
        }

        &.women-with-glasses {
          width: 35%;
          right: 5%;
          bottom: -24rem;
          left: unset;
        }
      }
    }
  }

  > .hit-section {
    margin-top: 6rem;

    @include respondTo(tablet) {
      margin-top: 10rem;
    }

    > .hit-header {
      font-family: 'Kobe-Bold';
      font-weight: bold;
      font-size: min(6rem, 13vw);
      line-height: 85%;
      text-align: center;
      position: relative;
      transition: all ease 0.3s;
      text-transform: uppercase;
      position: relative;
      @include respondTo(tablet) {
        max-width: min(97.4rem, 85vw);
        margin: 0 auto;
      }
      img.top-stars {
        position: absolute;
        max-width: 15vw;

        top: -5rem;
        left: 0rem;

        @include respondTo(tablet) {
          width: min(12.5rem, 9vw);
          left: 0;
          transform: translateX(max(-65%, -8rem));
        }
      }

      @include respondTo(tablet) {
        font-size: min(9rem, 15vw);
      }

      &.giving > img {
        position: absolute;
        left: 0;
        top: -4rem;
        height: min(7rem, 15vw);

        @include respondTo(tablet) {
          left: 15%;
          top: -5rem;
          height: min(9rem, 15vw);
        }

        @include respondTo(desktop) {
          left: 15%;
          top: -5rem;
          height: min(12rem, 15vw);
        }
      }

      &.receiving > img {
        position: absolute;
        right: -1rem;
        top: 10rem;
        height: min(7rem, 15vw);
        transform: rotate(-90deg);

        @include respondTo(tablet) {
          right: 10%;
          top: -4rem;
          height: min(9rem, 15vw);
          transform: rotate(0deg);
        }

        @include respondTo(desktop) {
          right: 15%;
          top: -4rem;
          height: min(12rem, 15vw);
          transform: rotate(0deg);
        }
      }
    }

    > .hit-content {
      max-width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-top: 5rem;
        max-width: 100%;
        flex-wrap: wrap;

        @include respondTo(mobile) {
          width: 100%;
        }

        @include respondTo(tablet) {
          width: 33%;
        }

        > .hit-image {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 4rem;
          height: 15rem;

          @include respondTo(tablet) {
            height: 12.8rem;
          }

          > img {
            height: 100%;
          }

          &.heart {
            width: 100%;
            padding: 0 20%;
          }
        }

        > .hit-title {
          text-align: center;
          font-size: 2.9rem;
          font-weight: bold;
          margin-bottom: 2rem;
          font-family: 'Agrandir-TextBold';
        }

        > .hit-description {
          text-align: center;
          font-size: 2rem;
          line-height: 140%;
        }
      }
    }
  }
}

.banner .button-title {
  color: $black;
}

.opt-in-page {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/svg/opt-in/OptInBackdrop.svg');
  background-position: left top 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $white;

  .logo {
    position: absolute;
    top: 1.7rem;
    left: 3.2rem;
    width: 12rem;
    cursor: pointer;
  }

  .opt-in-message-container {
    height: -webkit-fill-available;
    height: stretch;
    width: 100vw;
    box-sizing: unset;
    background-color: $white;
    border: unset;
    box-shadow: unset;
    padding: 4rem;
    z-index: 5;
    p {
      margin: 0;
    }
    .back-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2rem;
      gap: 1rem;
      cursor: pointer;
      span {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }
    .opt-in-input {
      margin-bottom: 2rem;
    }
    .special-error-text {
      margin-top: -2rem;
    }
    @include respondTo(mobile) {
      height: fit-content;
      width: 48rem;
      padding: 5.5rem;
      border: 2px solid $black;
      border-radius: 1.8rem;
      box-sizing: border-box;
      box-shadow: 8px 8px 0px $black;
    }
    p {
      margin: 0;
    }
    p.desc {
      font-size: 1.7rem;
      font-weight: normal;
      margin-bottom: 2rem;
      margin-top: -1rem;
      line-height: 2.7rem;
      &.centered {
        text-align: center;
      }
    }
    p.header {
      font-size: 2rem;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 2.5rem;
      &.centered {
        text-align: center;
      }
    }

    button:disabled,
    button[disabled] {
      opacity: 0.55;
      cursor: not-allowed;
    }
  }
}

.content-container.custom-banner {
  flex-wrap: wrap;
  .footer-heading {
    margin-bottom: 2rem;
  }
}

.opt-in-hero-title {
  font-size: min(9rem, 19.7vw);
  line-height: min(7.7rem, 18.5vw);
  font-family: 'Kobe-bold';
  font-weight: 700;
  @include respondTo(tablet) {
    font-size: min(12.4rem, 9vw);
    line-height: min(10.5rem, 7.6vw);
  }
}

.opt-in-inverse {
  color: white;
}

.big-o {
  font-family: 'Kobe-Bold';
  font-weight: 700;
  position: relative;
  img {
    position: absolute;
    width: 0.3em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -57%);
  }
}

// for firefox
@-moz-document url-prefix() {
  .big-o {
    img {
      top: 0.32em;
      left: 0.24em;
      transform: none;
    }
  }
}
