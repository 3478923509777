@use 'sass:map';
@import '../../styles/styles.scss';

.special-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 1.3rem;
  color: #000000;
  margin: 2rem 0;
  padding: 1.6rem 2.4rem;
  width: 100%;
  min-height: 5rem;
  border-radius: 0.9rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0.04em;

  &.success {
    background: map.get($alert-background-colors, 'success');
    border: 0.1rem solid map.get($alert-border-color, 'success');
  }

  &.info {
    background: map.get($alert-background-colors, 'info');
    border: 0.1rem solid map.get($alert-border-color, 'info');
  }

  &.warning {
    background: map.get($alert-background-colors, 'warning');
    border: 0.1rem solid map.get($alert-border-color, 'warning');
  }

  &.error {
    background: map.get($alert-background-colors, 'error');
    border: 0.1rem solid map.get($alert-border-color, 'error');
  }
  .alert-icon {
    width: 1.9rem;
    height: 1.9rem;
    left: 2.4rem;
    top: 1.6rem;
    margin: 0 1.2rem 0 0;
  }
}
