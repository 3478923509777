@import '../../styles/styles.scss';

.special-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2rem;
  text-transform: uppercase;
  width: 14.1rem;
  height: 4.6rem;
  background: $white;
  padding: 1.3rem 0rem;
  border: 0;

  &.active {
    background: $black;
    color: $white;
    box-shadow: 0rem 0.1rem 0.5rem $tabActiveBoxShadow;
    border-radius: 3.2rem;
  }

  &.regular {
    color: $line-1;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: $tabDisabledColor;
    &:hover {
      cursor: not-allowed;
    }
  }
}
