@import '../../styles/_variables.scss';
@import '../../styles/styles.scss';
@import '../../styles//breakpoints';

.settingsArrow {
  opacity: 0;
}

.settingsPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin: 0rem;
  }
}

.settingsContent {
  align-items: flex-start;
  margin: 4rem;
  width: 33rem;
  max-width: 90vw;
  @include respondTo(mobile) {
    margin: 8.5rem;
    width: 52.3rem;
  }
}

.settingsHero {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settingsIcon {
  padding-right: 2.6rem;
}

.settingsHeroTitle {
  font-family: Agrandir;
  font-weight: 700;
  font-size: 2.9rem;
  line-height: 4rem;
}

.settingsHeroSubTitle {
  font-family: Agrandir;
  font-weight: 400;
  font-size: 2rem;
}

.settingsDivider {
  border: 1px solid #dad6e7;
  margin-top: 3.5rem;
  margin-bottom: 2.8rem;
}

.settingsHeader {
  font-family: Agrandir;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #728291;
}

.settingsField {
  margin-top: 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.settingsField:hover {
  .settingsArrow {
    opacity: 1;
  }
}

.settingsDot {
  height: 2rem;
  width: 2rem;
  border: 0.4rem solid;
  border-radius: 50%;
  margin-right: 1.8rem;

  &.red {
    border-color: $red;
  }

  &.orange {
    border-color: $orange;
  }

  &.lillac {
    border-color: $soft-lilac;
  }

  &.seaGreen {
    border-color: $sea-green;
  }
}

.settingsFieldTitle {
  font-family: Agrandir;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 0.5rem;
}

.settingsFieldSubTitle {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #4d4d4d;
}

.settingsModalContainer {
  background-color: #00000040;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}
