.orderModalWrapper {
  p {
    margin: 0;
  }
}
.orderModalHeaderText {
  font-family: 'Agrandir-TextBold';
  font-style: normal;
  font-size: 29px;
  line-height: 140%;
}

.orderModalBoldText {
  font-family: 'Agrandir-TextBold';
  font-style: normal;
  font-size: 1.7rem;
  line-height: 140%;
}

.orderModalNumber {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 140%;
}

.orderModalSubText {
  font-family: 'Agrandir-TextBold';
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  color: #728291;
}

.orderModalContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.orderModalBackground {
  height: 100vh;
  width: calc(100vw - 72rem);
  background-color: #00000017;
}

.orderModal {
  background-color: white;
  padding-top: 2rem;
  word-break: break-word;
}

.orderModalRow {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.orderModalDownload {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  user-select: none;
}

.orderClose {
  background-image: url('../../assets/svg/Cross.svg');
  height: 4rem;
  width: 4rem;
  position: relative;
  top: -6rem;
  left: -6rem;
  cursor: pointer;
}

.orderRowItem {
  min-width: 20rem;
}

.orderModalDownloadText {
  text-transform: uppercase;
  font-family: Agrandir-TextBold;
}

.orderModalDivider {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
}
