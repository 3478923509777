.toothed {
  &:before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 1.5rem;
    top: 0;
    left: -1.5rem;
    background-image: url('../../assets/svg/HorizonTalWhiteTooth.svg');
  }
}
