@import '../../../../../styles/variables';

.valuesGridItem {
  height: 5rem;
  border: 0.1rem solid $line-2;
  border-radius: 0.9rem;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.purchase-grid-item-active {
  border: 2px solid $black;
  font-weight: bold;
}
