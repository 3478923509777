.content {

  &.our-mission {
    overflow-y: hidden;
    h1 {
      color: white;
    }
  }
}

.our-mission .fixed-col-wrapper {
  margin-bottom: -1rem;
}