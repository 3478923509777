@import '../../../../styles/variables';

.faq {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  .text {
    font-size: 1.3rem;
    line-height: 1.7rem;
    font-weight: bold;
    color: $black;
    text-decoration: none;
  }
}
