@import '../../styles/breakpoints';

.mobile-hide {
  display: none;

  @include respondTo(mobile) {
    display: inline;
  }
}

.mobile-show {
  display: inline;

  @include respondTo(mobile) {
    display: none;
  }
}