.arrowBack {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  cursor: pointer;
  .text {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.7rem;
    margin-left: 0.9rem;
  }
}
