@import '../../styles/breakpoints';

.privacy-wrapper {
  * {
    letter-spacing: 0.04rem;
    font-size: 2rem;
  }

  padding-bottom: 5rem;

  @include respondTo(laptop) {
    padding-bottom: 10rem;
  }
}
