@import '../../styles/_variables.scss';
@import '../../styles/styles.scss';
@import '../../styles//breakpoints';

.auth-page {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/svg/AuthBackdrop.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: #e3beff;
}

.auth-page-pre {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/svg/AuthBackdrop.svg');
  background-position-x: center;
  background-position-y: 22%;
  background-size: 150%;
  background-repeat: no-repeat;
  background-color: #e3beff;
  p.auth-subtitle {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 2.4rem;
  }
  p.auth-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding-top: 0;
    padding-bottom: 1.2rem;
  }
  @include respondTo(mobile) {
    background-position: unset;
    background-size: 100%;
  }
}

.session {
  background-image: url('../../assets/svg/SessionTimeOut.svg');
}
.auth-logo {
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 11.6rem;
}

.auth-container {
  height: -webkit-fill-available;
  height: stretch;
  width: 100vw;
  box-sizing: unset;
  background-color: $white;
  border: unset;
  box-shadow: unset;
  padding: 4rem;
  z-index: 5;
  p {
    margin: 0;
  }
  p.auth-create-account-subtitle {
    font-size: 1.7rem;
    font-weight: normal;
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  p.auth-create-account-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 2.5rem;
  }
  p.auth-legal {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.8rem;
    margin-bottom: 2rem;
  }

  @include respondTo(mobile) {
    height: fit-content;
    width: 48rem;
    padding: 5.5rem;
    border: 2px solid $black;
    border-radius: 1.8rem;
    box-sizing: border-box;
    box-shadow: 8px 8px 0px $black;
  }
}

.auth-container-pre {
  p {
    margin: 0;
  }

  p.auth-forgot-link {
    margin-top: -0.5rem;
    margin-bottom: 4rem;
  }

  p.auth-create-account-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 2.5rem;
  }

  position: fixed;
  bottom: 0px;
  height: 45%;
  box-sizing: unset;
  background-color: $white;
  border: unset;
  border-top-left-radius: 3.2rem;
  border-top-right-radius: 3.2rem;
  box-shadow: unset;
  width: -webkit-fill-available;
  width: stretch;
  padding: 4rem;
  @include respondTo(mobile) {
    position: relative;
    padding: 5.5rem;
    height: fit-content;
    width: 48rem;
    border: 2px solid $black;
    border-radius: 1.8rem;
    box-sizing: border-box;
    box-shadow: 8px 8px 0px $black;
  }
}

.auth-button {
  margin: 4rem 0rem 0rem 0rem;
}

.auth-input {
  margin-bottom: 2rem;
}

.auth-cancel {
  text-align: center;
  padding: 2rem 0rem 0rem;
  cursor: pointer;
  font-family: Agrandir;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.auth-stepper {
  padding-bottom: 2rem;
}

.auth-link {
  color: $electric-blue;
  text-decoration: underline;
  cursor: pointer;
}
