@import "../../../styles/breakpoints";

.title,
.title span {
  letter-spacing: unset;
  width: 100%;
  margin: 0;
  font-family: "Kobe-bold";
  font-weight: 600;
  font-size: min(9rem, 22vw);
  line-height: 0.85em;
  position: relative;

  &:last-child {
    margin-bottom: 4rem;
  }

  @include respondTo(tablet) {
    font-size: min(17rem, 15vw);
    line-height: 0.85em;
  }

  &.upper {
    text-transform: uppercase;
  }

  &.inverse {
    color: white;
  }

  &.sideBySide {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      flex: 1 0 auto;
    }
  }

  &.small,
  &.small > span {
    font-size: min(7rem, 19vw);

    @include respondTo(tablet) {
      font-size: min(17rem, 15vw);
      line-height: 0.85em;
    }
  }

  &.x-small {
    font-size: min(7rem, 15vw);

    @include respondTo(tablet) {
      font-size: min(17rem, 15vw);
      line-height: 0.85em;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }
}

.home .title {
  line-height: 1.04em;

  @include respondTo(mobile) {
    line-height: 0.85em;
  }
}

.icon {
  height: min(6rem, 20vw);
  padding-top: 1rem;
  line-height: 0.85em;

  @include respondTo(mobile) {
    max-width: none;
    padding-top: 0;
  }

  @include respondTo(tablet) {
    height: min(12rem, 12vw);
  }

  &.small &.small > span {
    height: max(4rem, 6vw);
    transform: translateY(-0.5rem);

    @include respondTo(tablet) {
      height: min(8rem, 8vw);
      transform: translateY(-1rem);
    }
  }

  &.card {
    transform: rotate(-10deg) scale(1);
    margin-top: -2rem;
    margin-left: 1rem;
    transition: all 0.1s ease;
    height: min(7.5rem, 20vw);

    @include respondTo(tablet) {
      transform: rotate(14deg) scale(1.1);
      margin-left: 3rem;
      height: min(12rem, 11vw);
    }
  }

  &.home-glasses {
    height: min(7.5rem, 13vw);
    padding-top: 0;
    padding-bottom: calc((1em - min(7.5rem, 12vw)) / 3);
    margin-left: -1rem;

    @include respondTo(mobile) {
      margin-left: 0;
      max-width: none;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include respondTo(tablet) {
      height: min(10rem, 10vw);
      padding-bottom: 1rem;
    }
  }

  &.home-eye {
    height: min(7.5rem, 14vw);
    padding-top: 0;
    padding-bottom: calc((1em - min(7.5rem, 12vw)) / 3);
    margin-right: -1rem;

    @include respondTo(mobile) {
      margin-right: 0;
      max-width: none;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include respondTo(tablet) {
      height: min(10rem, 10vw);
      padding-bottom: 1rem;
    }
  }

  &.home-cake {
    height: 0.7em;
    margin-left: -1.5rem;

    @include respondTo(tablet) {
      margin-left: 0;
    }

    @include respondTo(tablet) {
      height: min(12rem, 12vw);
    }
  }

  &.no-pad {
    padding: 0;
  }
}

.hit-icon {
  height: min(6rem, 15vw);

  @include respondTo(tablet) {
    height: min(11.5rem, 10vw);
  }
}

.mobile-hide {
  display: none;

  @include respondTo(mobile) {
    display: unset;
  }
}
