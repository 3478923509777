@use 'sass:map';
@import '../../styles/styles.scss';

.special-text-area-input {
  display: flex;
  position: relative;
  align-items: flex-start;

  .label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.3rem 0.2rem;
    margin: 1rem 1.5rem;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: $white;
    transition: transform 120ms ease-in;
    font-size: 1.6rem;

    &.error {
      color: $red;
    }
  }

  .field {
    outline: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: map.get($border, 'default');
    padding: 1.5rem 1.6rem 1.4rem;
    color: $black;
    background: $white;
    border-radius: map.get($border-radius, 'rounded');
    font-size: 1.6rem;
    min-height: 15rem;
    resize: vertical;

    // when not focused
    &:not(:placeholder-shown) {
      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $black;
      }
      
    }

    &:focus {
      border: map.get($border, 'selected');

      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $white;
        background: $electric-blue;
      }
    }
  }

  &.error {
    border-radius: map.get($border-radius, 'rounded');
    border: map.get($border, 'error')
  }
}
