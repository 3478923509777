@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.cardReveal {
  background-color: $sea-green;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .celebrationAnimations {
    position: absolute;
    width: 100%;
    z-index: 3;
  }
  .revealCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    position: relative;
    .woohoo {
      position: absolute;
      animation: woohoo-text-mobile 4s ease-in-out 1 forwards alternate;
      .text {
        color: $white;
        font-size: 14.5rem;
        line-height: 20.4rem;
        font-family: 'Kobe';
        font-weight: bold;
      }
    }
    .revealCardWrapper {
      display: inline-flex;
      position: absolute;
      animation: card-animate-mobile 6s ease-in-out forwards 1 alternate;
    }
  }

  .scallop {
    position: relative;
    top: 70px;
    animation: scallop-animation 2s ease-in-out 5s 1 forwards alternate;
  }
  .details {
    background-color: $white;
    position: relative;
    top: 100%;
    width: 100%;
    height: 0;
    animation: details-animation-mobile 2s ease-in-out 5s 1 forwards alternate;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 9;
    max-height: 60rem;
    .detailsWrapper {
      padding-bottom: 0.1rem;
    }
    .title {
      padding: 0 3rem;
      padding-top: 1rem;
      font-size: 2.9rem;
      font-weight: bold;
      line-height: 4.1rem;
      text-align: center;
      margin-bottom: 1rem;
      font-family: unset;
    }

    .outro {
      font-size: 2rem;
      line-height: 2.8rem;
      margin: 3rem;
      text-align: center;
    }

    .setPinButton {
      margin: 0 3rem;
      margin-bottom: 12.8rem;
    }
  }

  @include respondTo(tablet) {
    .revealCard {
      .woohoo {
        animation: woohoo-text 4s ease-in-out 1 forwards alternate;
        .text {
          font-size: 30rem;
          line-height: 42rem;
        }
      }
      .revealCardWrapper {
        position: absolute;
        animation: card-animate 6s ease-in-out forwards 1 alternate;
      }
    }

    .details {
      animation: details-animation 2s ease-in-out 5s 1 forwards alternate;
      max-height: unset;
      .detailsWrapper {
        max-width: 60rem;
        margin: 0 auto;
        margin-top: 1rem;
        .title {
          padding: 0 8rem;
          margin-top: 6rem;
        }

        .setPinButton {
          max-width: 35.5rem;
          margin: 0 auto;
          margin-bottom: 4.8rem;
        }
      }
    }
  }
}

@keyframes card-animate-mobile {
  0% {
    transform: translate(0, -50%) rotate(34deg) scale(1);
    top: 50%;
  }
  50% {
    transform: translate(0, -50%) rotate(0deg) scale(1);
    top: 50%;
  }
  100% {
    top: 1rem;
  }
}

@keyframes card-animate {
  0% {
    transform: translate(0, -50%) rotate(34deg) scale(1.5);
    top: 50%;
  }
  50% {
    transform: translate(0, -50%) rotate(0deg) scale(1.5);
    top: 50%;
  }

  75% {
    transform: translate(0, -50%) scale(1);
    top: 50%;
  }

  100% {
    top: 8rem;
  }
}

@keyframes woohoo-text-mobile {
  from {
    left: 100%;
  }
  to {
    left: -97rem;
  }
}

@keyframes woohoo-text {
  from {
    left: 100%;
  }
  to {
    left: -185rem;
  }
}

@keyframes details-animation-mobile {
  from {
    top: 100%;
  }

  to {
    top: 0;
    height: calc(100% - 30.5rem);
    overflow: visible;
  }
}

@keyframes details-animation {
  from {
    top: 100%;
  }

  to {
    top: 0;
    height: calc(100% - 44.5rem);
    overflow: visible;
  }
}

@keyframes scallop-animation {
  from {
    top: 100%;
  }

  to {
    top: 35px;
    height: 62px;
  }
}
