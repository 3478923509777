@import '../../../styles/breakpoints';

.setValueInputSection {
  margin-bottom: 2rem;
  .inputSection {
    margin-bottom: 0.8rem;
  }

  @include respondTo(tablet) {
    margin-bottom: 4rem;
  }
}
