@use 'sass:map';
@import '../../styles/styles.scss';

.special-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'Agrandir-GrandHeavy';
  font-size: 11px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.6rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  height: 2.4rem;
  width: max-content;
  padding: 0rem 0.9rem;
  padding-top: 0.7rem;
  padding-bottom: 0.2rem;
  letter-spacing: 0.1em;
  @include respondTo(tablet) {
    padding-top: 0.5rem;
  }

  &.red {
    background-color: map.get($tag-colors, 'red');
    color: $white;
  }

  &.orange {
    border: 0.2rem solid map.get($tag-colors, 'orange');
    color: $black;
  }

  &.green {
    background: map.get($tag-colors, 'green');
    border: 0.2rem solid $black;
    color: $black;
  }
  &.greenborder {
    border: 0.2rem solid map.get($tag-colors, 'green');
    color: $black;
  }
}
