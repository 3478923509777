@use 'sass:map';
@import '../../styles/styles.scss';

.special-header {
  max-width: 144rem;
  margin: 0 auto;
  padding: 0rem 1.6rem;
  z-index: 11;

  @include respondTo(tablet) {
    padding: 0rem 3.2rem;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    border-bottom: 0.2rem solid $black;
    padding: 1rem 0;
    gap: 0;
    justify-content: space-between;

    &.light-border {
      border-bottom-color: $line-2;
    }

    @include respondTo(tablet) {
      height: 7.2rem;
      padding: 0;
      gap: 4rem;
    }

    .header-center {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      > .menu {
        display: none;
        flex-direction: row;
        list-style-type: none;
        margin: 0;
        flex-wrap: wrap;
        gap: 4rem;
        height: 100%;
        flex: 1;

        > .header-message {
          font-size: 2rem;
          line-height: 140%;
          height: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .special-link {
          position: relative;
          top: 0.2rem;
          height: 100%;
          padding-top: 1rem;
          border-bottom: 0.3rem solid transparent;

          &.active {
            border-bottom-color: $black;
          }

          &.purchase-header-link {
            padding-top: 0.5rem;
            &.disabled {
              pointer-events: none;
            }
          }
        }

        @include respondTo(laptop) {
          gap: 3rem;
          display: flex;

          .special-link.purchase-header-link {
            display: flex;
          }
        }

        @media (min-width: 1224px) {
          .special-link.purchase-header-link {
            display: none;
          }
        }

        @include respondTo(desktop) {
          gap: 4rem;
          .special-link.purchase-header-link {
            display: flex;
          }
        }
      }
    }

    .header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4rem;
      height: 100%;

      .special-link {
        position: relative;
        top: 0.2rem;
        height: 100%;
        padding-top: 1rem;
        border-bottom: 0.3rem solid transparent;

        &.active {
          border-bottom-color: $black;
        }
      }

      > .logo {
        cursor: pointer;
        height: 100%;

        .special-link {
          padding-top: 0;
          height: 100%;
          border-bottom: 3px solid transparent;
        }
      }

      .shop-now-link {
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-weight: bold;
        display: none;

        @include respondTo(tablet) {
          display: flex;
        }

        > img {
          margin-bottom: 0.5rem;
        }
      }
    }

    .header-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 4rem;
      margin-top: 1rem;

      > .faq,
      > .menu {
        display: none;

        @include respondTo(tablet) {
          display: inherit;
        }
      }
    }
  }

  &.mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fffaf0;
    overflow: hidden;
    z-index: 15;

    &.active {
      transition: visibility 0s, opacity 0.5s linear;
      display: block;
    }

    @include respondTo(tablet) {
      display: none !important;
    }

    > .menu {
      height: calc(100vh - 12rem);
      overflow-y: auto;
      margin-bottom: 6rem;
      padding-top: 8rem;

      > .menu-item {
        margin-top: 1.8rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $black;

        .shop-now-icon-big {
          margin-right: 1rem;
          width: 3.2rem;
          height: 3.2rem;
        }
      }

      > .secondary-menu {
        margin: 5rem 0 6rem;
        > .secondary-menu-item {
          margin-top: 2rem;
          padding-bottom: 0.4rem;
          border-bottom: 0.1rem solid $sea-green;
          width: max-content;
          background-color: transparent;
        }
      }
    }
  }
}

.special-mobile-menu-button {
  display: flex;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;
  padding: 0rem 1.6rem;

  @include respondTo(tablet) {
    display: none;
  }

  .container {
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fffaf0;
    box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    padding: 0 2.4rem;
    border-radius: 0.9rem 0.9rem 0 0;

    &.black {
      background-color: $black;

      .shop-now {
        color: white;
      }
    }

    .shop-now {
      text-transform: uppercase;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      font-weight: bold;
      white-space: nowrap;
      height: 4.8rem;

      img {
        margin-left: 0.5rem;
        width: 3rem;
        position: relative;
        top: -0.3rem;
      }
    }
  }

  &.active {
    .container {
      box-shadow: none;
    }
  }
}
.white-hover:hover,
.white-hover > span:hover {
  color: #ffffff !important;
}
