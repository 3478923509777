@import '../../../styles//variables';
@import '../../../styles/breakpoints';

.unwrap {
  width: 100vw;
  height: 100vh;
  background-color: $white;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .colorLogo {
      display: none;
    }

    .blackLogo {
      padding: 3rem;
    }

    .topText {
      text-align: center;
      font-size: 2rem;
      align-self: center;
      padding: 0 3rem;
    }
  }

  .giftWrapper {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .gift {
      position: relative;

      .present {
        display: block;
        margin: 0 auto;
        max-width: 45rem;
        animation: shake 2s ease-in-out forwards infinite;
        transform-origin: bottom;
      }
      .pointer {
        position: absolute;
        width: 5.5rem;
        height: 4rem;
        left: 50%;
        top: -20%;
        transform: translate(-50%, 0);
      }

      .topStars {
        position: absolute;
        top: -30%;
        height: 6.5rem;
        width: 6.5rem;
      }

      .bottomStars {
        position: absolute;
        bottom: -20%;
        right: -15%;
        height: 6.5rem;
        width: 6.5rem;
      }

      .shadow {
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 22rem;
      }
    }
  }

  @include respondTo(tablet) {
    .top {
      position: relative;
      min-height: 7.2rem;
      border-bottom: 2px solid $whiteStickerBorderColor;
      align-items: center;
      .colorLogo {
        display: block;
        position: absolute;
        top: 1.7rem;
        left: 3.2rem;
      }

      .blackLogo {
        display: none;
      }
      .topText {
        padding: 0;
      }
    }

    .giftWrapper {
      .gift {
        .pointer {
          width: 8.2rem;
          height: 5.8rem;
          left: 50%;
          top: -20%;
        }

        .topStars {
          top: -20%;
          left: -35%;
          height: 9rem;
          width: 9rem;
        }

        .bottomStars {
          height: 9rem;
          width: 9rem;
          right: -35%;
        }

        .shadow {
          width: 32.7rem;
        }
      }
    }
  }
}

@keyframes shake {
  70% {
    transform: rotate(0deg);
  }
  77% {
    transform: rotate(15deg);
  }

  82% {
    transform: rotate(-15deg);
  }

  87% {
    transform: rotate(15deg);
  }

  92% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
