@import '_variables.scss';

a {
  color: $sea-green;
}

.content {
  margin: 0 auto;
  padding: 0 1.6rem;
  overflow-x: hidden;

  @include respondTo(tablet) {
    overflow-x: unset;
    max-width: 144rem;
    padding: 0 3.2rem;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
