@import '../../styles/breakpoints';

.apple-wrapper {
  background-color: #e9c0ff;

  h2 {
    color: black;
    line-height: 8rem;
    text-align: center;
    font-size: 6rem;
    line-height: 6.5rem;
    font-family: 'Kobe-Bold';

    &.faqs {
      margin-top: 6rem;
      
      @include respondTo(tablet) {
        margin-top: 11rem;
      }
    }

    &.use {
      line-height: 5.4rem;
      margin: 0 auto 5rem auto;

      @include respondTo(tablet) {
        line-height: 8rem;
      }
    }

    @include respondTo(tablet) {
      font-size: 9rem;
      line-height: 8rem;
    }

    span {
      font-family: 'Kobe-Bold';
      text-transform: uppercase;
      font-size: 6rem;
      line-height: 5rem;

      @include respondTo(tablet) {
        font-size: 9rem;
        line-height: 8rem;
      }
    }
  }

  h3 {
    color: black;
    font-family: 'Agrandir-TextBold';
    font-weight: normal;
  }

  .phone-watch {
    max-width: 110rem;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 2rem;

    h3 {
      font-size: 4rem;
    }

    > div {
      > :first-child {
        margin-bottom: 2rem;
      }

      > div {
        display: grid;
        grid-template-columns: 3rem 1fr;
        grid-template-rows: auto auto;
        column-gap: 2rem;

        img {
          margin-top: 1.2rem;
          width: 3rem;
          height: 3rem;        
        }

        h3 {
          margin: 0 0 3rem 0;
        }

        p {
          grid-column: 2;
          margin: 0;
        }
      }
    }

    @include respondTo(tablet) {
      column-gap: 6rem;
      row-gap: 4rem;
      grid-template-columns: 4fr 6fr;
      grid-template-rows: auto auto;
    }

    @include respondTo(laptop) {
      column-gap: 9rem;
      row-gap: 6rem;
    }

    > :first-child {
      width: 100%;
      max-width: 38rem;
      margin: 0 auto 3rem auto;

      @include respondTo(tablet) {
        grid-row: 1/3;
        margin: unset;
      }
    }

    .title {
      font-size: 4rem;
    }

    .body {
      font-size: 2.4rem;
    }
  }

  .sub-heading {
    margin: auto;
    text-align: center;

    &.set {
      font-size: 3.4rem;
      margin: 4rem auto;

      @include respondTo(tablet) {
        max-width: 77rem;
        margin: 11rem auto 10rem auto;
      }
    }

    &.safe {
      margin-bottom: 4rem;

      @include respondTo(tablet) {
        text-align: center;
      }
    }
  }

  .blurby {
    font-size: 2.4rem;
    line-height: 3.3rem;

    b {
      font-family: 'Agrandir-TextBold';
      letter-spacing: 0.04rem;
    }

    &.centered {
      margin: auto;
      text-align: center;
    }

    &.bold {
      font-family: 'Agrandir-TextBold';
    }

    &.col-72 {
      max-width: 72rem;
      margin: 0 auto 3rem auto;

      @include respondTo(tablet) {
        margin: 11rem auto 10rem auto;
      }
    }

    &.col-60 {
      max-width: 60rem;
      margin: 0 auto 3rem auto;

      @include respondTo(tablet) {
        margin: 11rem auto 10rem auto;
      }
    }
  }

  .videos {
    max-width: 120rem;
    margin: 5rem auto 6rem auto;
    display: grid;
    gap: 5rem;

    @include respondTo(tablet) {
      margin: 0 auto;
    }

    iframe {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-height: 31.2rem;
      max-width: 52.3rem;
    }

    h3 {
      font-family: "Agrandir-TextBold";
      font-size: 4rem;
      text-align: center;
      margin: 0 0 3rem 0;

      @include respondTo(tablet) {
        margin: 0 0 5rem 0;
      }
    }

    @include respondTo(tablet) {
      grid-template-columns: 1fr 1fr;
      gap: 10%;
    }
  }

  .disclaimer {
    font-size: 1.3rem;
    text-align: left;
    margin: 3rem auto 5rem auto;

    @include respondTo(tablet) {
      text-align: center;
      margin: 11.5rem auto 6rem auto;
    }
  }

  .icons {
    padding-top: 2.5rem;

    @include respondTo(tablet) {
      padding-top: 4rem;
    }

    > * {
      margin: 0 0.75rem;
    }
  }

  .faqs-accordian {
    max-width: 80rem;
    margin: 0 auto;
  }
}
