@import '../../styles/_variables.scss';
@import '../../styles/styles.scss';
@import '../../styles//breakpoints';

.my-orders {
  p {
    margin: 0;
  }
}

.orderTabs {
  display: flex;
  justify-content: center;
  margin-top: 3.7rem;
  margin-bottom: 3rem;
  @include respondTo(tablet) {
    margin: 6rem;
  }
}

.noOrderImage {
  background: rgb(0, 204, 168, 00);
  position: fixed;
  height: 60vh;
  bottom: 0;
  right: unset;
  z-index: 10;

  @include respondTo(tablet) {
    height: calc(100vh - 8rem);
    right: 42vw;
  }
}

.noOrderModal {
  position: absolute;
  background: #ffffff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
  padding: 5.5rem;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 8px 8px 0px #000000;
  width: 48rem;
  height: fit-content;
  border-radius: 18px;
  z-index: 5000;
}

.noOrderBackground {
  background: rgb(0, 204, 168);
  background: radial-gradient(
    circle,
    rgba(0, 204, 168, 1) 36%,
    rgba(255, 255, 255, 1) 36%
  );
  background-position: left;
  background-repeat: repeat-x;
  background-size: 7.9rem 21.2rem;
  background-position: top;
  content: ' ';
  display: block;
  position: fixed;
  top: 50%;
  left: 0;
  height: 10.5rem;
  width: 100%;
}

.noOrderBackground::after {
  background: rgb(0, 204, 168);
  position: fixed;
  content: ' ';
  bottom: 0;
  height: calc(50% - 10.5rem);
  width: 100vw;
}

.orderContainer {
  max-width: 144rem;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.orderRow {
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  max-width: 105.8rem;
  padding: 3.6rem;
  margin: 1rem 2rem;
  justify-content: flex-start;
  gap: 2rem;
  flex-grow: 1;
  align-items: center;
  border: 1px solid #dad6e7;
  cursor: pointer;
  border-radius: 9px;
  @include respondTo(tablet) {
    justify-content: space-between;
    margin: 0;
    margin-bottom: 3rem;
  }
}

.orderArrow {
  opacity: 0;
}

.orderRow:hover {
  border: 1px solid #000000;
  .orderArrow {
    opacity: 1;
  }
}

.orderBoldText {
  font-family: 'Agrandir-TextBold';
  font-style: normal;
  font-size: 2rem;
  line-height: 140%;
}

.orderNumber {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 140%;
}

.orderSubText {
  font-family: 'Agrandir-TextBold';
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  color: #728291;
}

.orderInfo {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-grow: 1;
}

.orderData {
  flex-grow: 1;
  min-width: 50%;
}

.no-order-content {
  position: fixed;
  text-align: center;
  padding: 2.8rem;
  .content-title {
    font-weight: bold;
    font-size: 2.9rem;
    margin-top: 4rem;
  }

  .content-text {
    font-size: 2rem;
    margin-top: 1rem;
    line-height: 2.8rem;
  }

  button {
    height: unset;
    padding: 1rem 2rem;
    margin: 0 auto;
    width: auto;
  }
}
