.side-drawer-loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  height: 100%;

  .loader {
    animation: rotate 3s linear infinite;
    margin: 0 auto;
    width: 7.3rem;
    height: 7.3rem;
  }
  p {
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: 2rem;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
}
