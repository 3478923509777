@import '../../styles/breakpoints';

.notfound-text {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 140%;
  letter-spacing: 0.04em;
  margin: 0 0 2.4rem;
}

.notfound-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12rem -1rem 0;

  > *:nth-child(1) {
    flex: 0 0 22rem;
  }

  > *:nth-child(2) {
    flex: 0 0 28rem;
  }

  > button.notfound-home-button,
  > button.notfound-previous-button {
    margin: 1rem;
    flex-grow: 1;

    @include respondTo(tablet) {
      flex-grow: 0;
    }
  }
}

.notfound-container {
  margin-top: 3rem;
  max-width: 90rem;
  
  display: flex;
  flex-direction: column;
  padding-bottom: 12em;
  margin-bottom: auto;

  @include respondTo(tablet) {
    padding-left: 3rem;
  }
}

.notfound-previous-button {
  width: 26rem;
}
