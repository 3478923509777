@import "../../styles/variables";

.special-container {
  display: flex;
  flex-direction: row;
}

.special-step-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.special-step-dot {
  height: 15px;
  width: 15px;
  border-style: solid;
  border-radius: 15px;
  border-width: 4px;
}

.special-dot-separator {
  height: 1px;
  width: 16px;
  background-color: $black;
  margin-left: 2px;
  margin-right: 2px;
}
