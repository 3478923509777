.social-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.4rem;
  gap: 1.6rem;
}
.social-sign-in-warning {
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 2rem;
  text-align: center;
}
