@import '../../styles/variables';
@import '../../styles/breakpoints';

.purchase-flow-header {
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin-top: 3rem;
  margin-bottom: 0.8rem;
  font-weight: bold;
  letter-spacing: 0;
}

.purchase-flow-description {
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 2rem;
}

.purchase-flow-back {
  margin: 2.8rem 2.2rem;
  margin-left: 0;
}

@include respondTo(tablet) {
  .purchase-flow-header {
    font-size: 2.9rem;
    line-height: 4.1rem;
  }

  .purchase-flow-description {
    margin-bottom: 4rem;
  }

  .purchase-flow-back {
    margin: 3.2rem;
    margin-left: 0;
  }

  .purchase-error-text {
    margin-top: 0.8rem;
  }
}
