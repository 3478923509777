@use 'sass:map';
@import '../../styles/styles.scss';

.special-input {
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
  height: 5rem;

  &.error {
    margin-bottom: 0.5rem;
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.3rem 0.2rem;
    margin: 1rem 1.5rem;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: $white;
    transition: transform 120ms ease-in;
    font-size: 1.6rem;

    &.force-label-up {
      transform: translate(-0.1rem, -75%) scale(0.8);
      padding: 0.1rem 0.5rem 0.1rem 0.6rem;
      border-radius: map.get($border-radius, 'rounded-sm');
      color: $black;
      &.error {
        color: $red;
      }
    }

    &.error {
      color: $red;
    }
  }

  .field {
    outline: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: map.get($border, 'default');
    padding: 1.35rem 1.7rem;
    color: $black;
    background: $white;
    border-radius: map.get($border-radius, 'rounded');
    font-size: 1.6rem;

    &.password-field {
      padding-right: 7.5rem;
    }

    &.error {
      border: map.get($border, 'error');
      color: $red;
    }

    // when not focused
    &:not(:placeholder-shown) {
      &.error {
        border: map.get($border, 'error');
      }

      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $black;
        &.error {
          color: $red;
        }
      }
    }

    &:focus {
      border: map.get($border, 'selected');

      &.error {
        border: map.get($border, 'error-selected');
      }

      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $white;
        background: $electric-blue;
        margin: 1rem 1.8rem;

        &.error {
          background: linear-gradient(
              0deg,
              map.get($alert-border-color, 'error'),
              map.get($alert-border-color, 'error')
            ),
            $white;
          color: $black;
        }
      }
    }
  }

  .show-password {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-right: 2rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: bold;
  }

  input[type='password'] {
    font-family: 'diamond';
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 2.1rem;
    letter-spacing: 0.3rem;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.select {
    position: relative;
    box-sizing: border-box;
    border: map.get($border, 'default');
    border-radius: map.get($border-radius, 'rounded');
    cursor: pointer;
    background-color: $white;

    .label {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0.3rem 0.2rem;
      margin: 1.3rem 1.5rem;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: $white;
      transition: transform 120ms ease-in;
      font-size: 1.6rem;
    }

    .focus-label {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-0.1rem, -75%) scale(0.8);
      transform-origin: 0 0;
      padding: 0.1rem 0.5rem 0.1rem 0.6rem;
      border-radius: map.get($border-radius, 'rounded-sm');
      color: $white;
      background: $electric-blue;
      margin: 1rem 1.8rem;
    }

    .value-label {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-0.1rem, -75%) scale(0.8);
      padding: 0.1rem 0.5rem 0.1rem 0.6rem;
      transform-origin: 0 0;
      border-radius: map.get($border-radius, 'rounded-sm');
      color: $black;
      background-color: $white;
      margin: 1rem 1.8rem;
    }

    .value {
      width: 100%;
      height: inherit;
      padding: 1.35rem 1.7rem;
      display: flex;
      justify-items: center;
      align-items: center;
      position: relative;

      &::after {
        content: url('../../assets/svg/SelectDownArrow.svg');
        position: absolute;
        right: 2rem;
      }

      &.active::after {
        content: url('../../assets/svg/SelectUpArrow.svg');
        position: absolute;
        right: 2rem;
      }
    }

    .select-options {
      top: 100%;
      left: 0;
      position: absolute;
      padding: 0;
      margin: 0 -0.1rem;
      width: calc(100% + 0.2rem);
      border: map.get($border, 'default');
      border-radius: map.get($border-radius, 'rounded');
      list-style-type: none;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-top-width: 0 !important;
      padding-bottom: 0.5rem;
      background: #ffffff;
      z-index: 10;

      li {
        padding: 0.4rem 1.9rem;

        &:hover {
          background: $options-background-hover;
          cursor: pointer;
        }
      }
    }

    &.active-dropdown {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-color: transparent;
    }
  }
}
.select-error {
  border-color: $red !important;
}
