.social-links {
  display: flex;
  gap: 2.5rem;
}

.mobile-light,
.mobile-dark {
  display: flex;
  align-items: center;
  padding: 1rem 2rem 0.5rem;
  border-radius: 1rem;
}

.mobile-light {
  background: #000000;
}

.mobile-dark {
  background: #ffffff;
}