@use 'sass:map';
@import '../../styles/styles.scss';

.footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  &.ridged:before {
    content: '';
    position: absolute;
    height: 1.5rem;
    width: 100%;
    top: -1.5rem;
    left: 0;
    background-position: center;
    background-image: url('../../assets/svg/Tooth.svg');
  }

  * {
    color: #000000;
    border-color: #000000;
  }

  &.black {
    background-color: #000000;
  }

  &.black,
  &.electric-blue {
    * {
      color: #ffffff;
      border-color: #ffffff;
    }
  }

  > .footer-inner {
    width: 100%;
    max-width: 144rem;
    padding: 1.6rem 1.6rem 6rem;

    @include respondTo(tablet) {
      padding: 1.6rem 3.2rem;
    }

    > .main {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 4rem 0 2rem;

      @include respondTo(tablet) {
        flex-direction: row;
      }

      > * {
        margin-bottom: 2rem;
      }

      .logo-section {
        justify-content: space-between;
        display: flex;
        margin-bottom: 5.5rem;

        > .social-links {
          display: flex;
          align-items: center;
        }

        @include respondTo(tablet) {
          flex: 0 0 20rem;

          > *:last-child {
            display: none;
          }
        }
      }

      .main-links {
        min-height: 13rem;
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 1rem 0.5rem;

        @include respondTo(tablet) {
          flex: 1 0 auto;
          grid-template-columns: repeat(auto-fit, 18rem);
          gap: 3.5rem 0;
        }

        .footer-link-section {
          display: flex;
          flex-direction: column;
          a {
            margin-bottom: 1rem;
            text-decoration: none;
          }
        }

        @include respondTo(mobile) {
          grid-template-columns: repeat(auto-fit, 20rem);
          gap: 3.5rem 0;
        }

        > .main-link-section {
          height: min-content;
          display: flex;
          flex-direction: column;
        }

        a {
          text-decoration: none;
          margin-bottom: 10px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .app-links {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 0.5rem;
        margin-bottom: 0rem;

        .link {
          margin-bottom: 0.7rem;
        }

        > a {
          width: 50%;
          max-width: 17rem;
          padding: 0 1rem 0 0;

          @include respondTo(mobile) {
            width: 100%;
          }
        }

        button {
          padding: 0;
          width: 100%;
        }

        > .section-header {
          display: none;
        }

        @include respondTo(tablet) {
          flex-direction: column;
          width: auto;
          padding-top: 0rem;
          margin-bottom: 2rem;

          .section-header {
            display: block;
          }
        }
      }

      .section-header {
        text-transform: uppercase;
        font-size: 1.3rem;
        font-family: 'Agrandir-TextBold';
        margin-bottom: 2.5rem;
      }

      a {
        font-size: 2rem;
      }
    }

    > .base {
      > .social-legal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 0;
        border-top: 0.2rem solid;

        > *:first-child {
          display: none;

          @include respondTo(tablet) {
            display: flex;
          }
        }

        > .legal-links > .link {
          font-family: 'Agrandir-TextBold';
          text-transform: uppercase;
          text-decoration: none;

          &:not(:last-child)::after {
            content: '•';
            margin: 0 0.75rem;
            cursor: default;
            user-select: none;
          }
        }
      }

      > .legal-notice {
        border-top: 0.1rem solid;
        margin: 0;
        font-size: 1.3rem;
        padding: 2.4rem 0 1.6rem;
        letter-spacing: 0.05em;

        * {
          font-size: 1.3rem;
        }
      }
    }
  }
}

.cta-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100rem;

  .footer-heading {
    font-size: 2.9rem;
    margin-bottom: 0rem;
    margin-top: 1rem;
  }

  p {
    margin: 0 0 1.5rem 0;
    letter-spacing: 0.04em;
    font-size: 2rem;
    line-height: 2.8rem;
  }

  > * {
    color: #ffffff;
    padding: 0 2.25rem;
  }

  > img {
    width: 33%;
    max-width: 15.5rem;
    min-width: 12rem;
    transform: rotate(-6deg);
  }

  > div {
    flex: 1 1 auto;
    max-width: 64rem;
    min-width: 24rem;
  }
}
