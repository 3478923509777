@import '../../styles/breakpoints';

.shop-wrapper {
  padding-bottom: 5rem;

  h1,
  h2 {
    font-family: 'Kobe-Bold';
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h1 {
    font-size: 6rem;
    text-align: center;

    @include respondTo(tablet) {
      font-size: 9rem;
    }

    img {
      &:first-of-type {
        width: 3.1rem;
        margin-right: 1rem;
        transform: translateY(-0.5rem);

        @include respondTo(tablet) {
          width: 4.7rem;
          transform: translateY(-1.5rem);
        }
      }
      &:last-of-type {
        margin-left: 1.5rem;
        width: 2.6rem;

        @include respondTo(tablet) {
          width: 3.8rem;
          transform: translateY(-1rem);
        }
      }
    }
  }
  h2 {
    font-size: 4rem;

    @include respondTo(tablet) {
      font-size: 6rem;
    }
  }

  .subheading {
    margin: 2rem 0 4rem 0;
    font-size: 1.7rem;
    &.edge-p {
      padding: 0 1.5rem;
    }

    &.center {
      text-align: center;
    }

    @include respondTo(tablet) {
      font-size: 2rem;
    }
  }

  .search-container {
    padding: 0.5rem 1.5rem;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;

    @include respondTo(tablet) {
      padding: 0.5rem 3rem;
    }
    
    .sticky {
      display: flex;
      margin: 0.5rem 0;
      max-width: none;

      .search-wrapper{
        width: 100%;
      }
      
      @include respondTo(laptop) {
        max-width: 56.5rem;
        margin: 0.5rem auto 0.5rem auto;
        display: block;

        .search-wrapper {
          margin-right: 0rem;
          flex: 1 0 auto;
        }
      }
    }

    .search-button {
      display: none;
      top: -5rem;
      opacity: 0;
      position: unset;
      
      button {
        padding: 1rem;
        min-width: 12rem;
      }

      &.at-top {
        display: unset;
        top: 1rem;
        opacity: 1;
      }

      @include respondTo(laptop) {
        transition: top 0.3s ease;
        top: -5rem;
        right: max(3rem, calc(100vw - 139rem) / 2 - 0.2rem);
        position: absolute;
        display: block;
        opacity: 0;
      }
    }
  }

  .scroller-container {
    transform: rotate(-10deg);
    margin: 7rem -5rem;

    @include respondTo(tablet) {
      transform: rotate(0);
      margin: 8rem 0;
    }
  }
}
