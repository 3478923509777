@import '../../styles/breakpoints';
@import '../../styles/variables';

strong {
  font-weight: bold;
}

.illustration-icon {
  max-width: 18rem;
}

.opt-out-subtitle {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: min(1rem, 10vw);

  .trade {
    position: absolute;
    top: 5px;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.3rem;

    @include respondTo(tablet) {
      font-size: 2rem;
      line-height: 1.8rem;
    }
  }

  @include respondTo(desktop) {
    margin-top: 4rem;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-size: min(17rem, 15vw);
  }

  > .subtitle {
    width: 100%;
  }

  > .right-subtitle {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    cursor: pointer;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respondTo(tablet) {
      margin-top: 0rem;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    > * {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &:not(:last-child) {
        margin-right: 4rem;
      }

      @include respondTo(tablet) {
        transform: scale(1);
      }

      > span {
        font-size: 2rem;
        font-weight: bold;
        margin-right: 1rem;

        @include respondTo(tablet) {
          font-size: 2.4rem;
          margin-right: 2rem;
        }
      }

      > img {
        width: 3rem;
        @include respondTo(tablet) {
          width: 5rem;
        }
      }
    }
  }
}

.opt-out {
  .out-out-container {
    padding-left: 0;
    @include respondTo(tablet) {
      padding-left: min(9rem, 6.25vw);
    }
  }
  .out-out-description {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .opt-out-container {
    margin-top: 8rem;
    display: flex;
    flex-direction: column-reverse;
    padding-left: 0;
    @include respondTo(tablet) {
      padding-left: min(9rem, 6.25vw);
      flex-direction: row;
      > div {
        margin-right: 5rem;
      }
    }

    .image-card {
      max-width: 60rem;
      width: 100%;
      @include respondTo(tablet) {
        width: 62%;
      }
    }

    .opt-out-header {
      font-weight: bold;
      font-size: 4rem;
      margin-bottom: 4rem;
      line-height: 5.2rem;
    }

    button {
      width: auto;
      margin-top: 4rem;
    }
  }

  .opt-out-bottom-container {
    margin-top: 8.2rem;
    margin-bottom: 26rem;
    display: flex;
    flex-direction: row;
    position: relative;

    .out-out-description {
      margin-bottom: 4rem;
    }

    @include respondTo(tablet) {
      margin-top: 10rem;
      margin-bottom: 13rem;
      > div {
        width: 60%;
        margin-right: 5rem;
      }
    }

    img {
      position: absolute;
      &.colorful-stars {
        width: 6rem;
        left: 1.5rem;
        bottom: -3rem;
      }

      &.women-with-glasses {
        width: 36rem;
        left: -3.5rem;
        bottom: -26rem;
      }
    }

    @include respondTo(tablet) {
      img {
        position: absolute;
        &.colorful-stars {
          width: min(12.5rem, 8.6vw);
          top: 0;
          left: 0;
          transform: translate(20%, -105%);
        }

        &.women-with-glasses {
          width: 44.5%;
          right: 5%;
          bottom: -13rem;
          left: unset;
        }
      }
    }
  }

  > .hit-section {
    margin-top: 6rem;

    @include respondTo(tablet) {
      margin-top: 10rem;
    }

    > .hit-header {
      font-family: 'Kobe-Bold';
      font-weight: bold;
      font-size: min(6rem, 13vw);
      line-height: 85%;
      text-align: center;
      position: relative;
      transition: all ease 0.3s;
      text-transform: uppercase;

      @include respondTo(tablet) {
        font-size: min(7rem, 15vw);
      }

      @include respondTo(desktop) {
        font-size: min(9rem, 15vw);
      }

      &.giving > img {
        position: absolute;
        left: 0;
        top: -4rem;
        height: min(7rem, 15vw);

        @include respondTo(tablet) {
          left: 15%;
          top: -5rem;
          height: min(9rem, 15vw);
        }

        @include respondTo(desktop) {
          left: 15%;
          top: -5rem;
          height: min(12rem, 15vw);
        }
      }

      &.receiving > img {
        position: absolute;
        right: -1rem;
        top: 10rem;
        height: min(7rem, 15vw);
        transform: rotate(-90deg);

        @include respondTo(tablet) {
          right: 10%;
          top: -4rem;
          height: min(9rem, 15vw);
          transform: rotate(0deg);
        }

        @include respondTo(desktop) {
          right: 15%;
          top: -4rem;
          height: min(12rem, 15vw);
          transform: rotate(0deg);
        }
      }
    }

    > .hit-content {
      max-width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-top: 5rem;
        max-width: 100%;
        flex-wrap: wrap;

        @include respondTo(mobile) {
          width: 100%;
        }

        @include respondTo(tablet) {
          width: 33%;
        }

        > .hit-image {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 4rem;
          height: 15rem;

          @include respondTo(tablet) {
            height: 12.8rem;
          }

          > img {
            height: 100%;
          }

          &.heart {
            width: 100%;
            padding: 0 20%;
          }
        }

        > .hit-title {
          text-align: center;
          font-size: 2.9rem;
          font-weight: bold;
          margin-bottom: 2rem;
          font-family: 'Agrandir-TextBold';
        }

        > .hit-description {
          text-align: center;
          font-size: 2rem;
          line-height: 140%;
        }
      }
    }
  }
}

.banner .button-title {
  color: $black;
}

.opt-out-page {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/svg/opt-out/OptOutBackdrop.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $white;

  .logo {
    position: absolute;
    top: 1.7rem;
    left: 3.2rem;
    width: 12rem;
    cursor: pointer;
  }

  .opt-out-message-container {
    height: -webkit-fill-available;
    height: stretch;
    width: 100vw;
    box-sizing: unset;
    background-color: $white;
    border: unset;
    box-shadow: unset;
    padding: 4rem;
    z-index: 5;
    p {
      margin: 0;
    }
    .back-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2rem;
      gap: 1rem;
      cursor: pointer;
      span {
        font-weight: bold;
        font-size: 1.3rem;
      }
    }
    .opt-out-input {
      margin-bottom: 2rem;
    }
    .special-error-text {
      margin-top: -2rem;
    }
    @include respondTo(mobile) {
      height: fit-content;
      width: 48rem;
      padding: 5.5rem;
      border: 2px solid $black;
      border-radius: 1.8rem;
      box-sizing: border-box;
      box-shadow: 8px 8px 0px $black;
    }
    p {
      margin: 0;
    }
    p.desc {
      font-size: 1.7rem;
      font-weight: normal;
      margin-bottom: 2rem;
      margin-top: -1rem;
      &.centered {
        text-align: center;
      }
    }
    p.header {
      font-size: 2rem;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 2.5rem;
      &.centered {
        text-align: center;
      }
    }

    button:disabled,
    button[disabled] {
      opacity: 0.55;
      cursor: not-allowed;
    }
  }
}

.content-container.custom-banner {
  flex-wrap: wrap;
  .footer-heading {
    margin-bottom: 2rem;
  }
}
