@use 'sass:map';
@import '../../styles/styles.scss';

.special-options {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  height: 4.6rem;
  margin-bottom: 1rem;
  border-radius: map.get($border-radius, 'rounded');

  > input {
    appearance: none;
    height: 2.2rem;
    width: 2.2rem;
  }

  // Radio button
  > input[type='radio'] {
    display: none;

    &:checked {
      + span {
        border: map.get($border, 'selected');
        &:before {
          content: '';
          position: absolute;
          top: calc(50% - 0.4rem);
          left: calc(50% - 0.42rem);
          width: 0.8rem;
          height: 0.8rem;
          background-color: $black;
          border-radius: map.get($border-radius, 'rounded-full');
        }
      }
    }

    + span {
      position: relative;
      display: inline-block;
      flex: none;
      height: 2.2rem;
      width: 2.2rem;
      margin-top: 0.2rem;
      margin-right: 0.8rem;
      border-radius: map.get($border-radius, 'rounded-full');
      border: map.get($border, 'default');
    }
  }

  // Checkbox
  > input[type='checkbox'] {
    -webkit-appearance: none;
    border: map.get($border, 'default');
    border-radius: map.get($border-radius, 'rounded-sm');
    display: inline-block;
    position: relative;
    margin-right: 0.8rem;

    &:checked {
      background-color: $black;

      &:after {
        content: url('../../assets/svg/CheckWhite.svg');
        position: absolute;
        padding: 0.1rem 0.3rem;
        color: #ffffff;

        @media not all and (min-resolution: 0.001dpcm) {
          padding: 0.3rem;
        }
      }
    }
  }

  &:hover {
    background: $options-background-hover;
  }
}

.special-options-square {
  input[type='radio'] {
    display: none;

    &:checked {
      + .box {
        border: map.get($border, 'selected');
        font-weight: bold;
      }
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: normal;

    border: map.get($border, 'default');
    border-radius: map.get($border-radius, 'rounded');
    cursor: pointer;

    &.big {
      height: 8rem;
      padding: 2.4rem 1.6rem;
    }

    &.small {
      height: 3rem;
      padding: 0.8rem 0.8rem 0.6rem;
    }
  }
}
