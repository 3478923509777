@import '../../styles/breakpoints';

.timeline-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 110rem;
  padding: 0;
  align-items: flex-start;
  height: 50rem;

  @include respondTo(tablet) {
    max-width: 144rem;
    padding: 0 3.2rem;
    margin: 0 auto;
    flex-direction: row;
    align-items: flex-start;
    height: unset;
  }

  div {
    counter-increment: li-count;
    position: relative;
    z-index: 3;
    text-align: center;
    height: 100%;

    @include respondTo(tablet) {
      padding-top: 3rem;
      width: 20%;
    }

    br {
      display: none;
      
      @include respondTo(tablet) {
        display: inline;
      }
    }

    p {
      margin: -1rem 0 0 4rem;
      font-size: 1.7rem;
      text-align: left;

      @include respondTo(tablet) {
        margin: 0 3rem;
        text-align: center;
      }
    }

    &:after {
      position: absolute;
      content: counter(li-count);
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      justify-content: center;
      text-align: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      left: -1rem;
      top: -1.5rem;
      background: black;
      color: white;
      margin: 0;
      left: 0;
      right: unset;

      @include respondTo(tablet) {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      &:before {
        content: '';
        height: 100%;
        border-left: solid 0.2rem black;
        position: absolute;
        left: 1.4rem;

        @include respondTo(tablet) {
          transform: translateY(-3rem);
          width: 100%;
          border-top: solid 0.2rem black;
          border-left: unset;
          left: 50%;
        }
      }
    }
  }
}
