@use 'sass:map';
@import '../../styles/styles.scss';

.banner {
  background-color: #000000;
  min-height: 22.5rem;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;

  * {
    color: #ffffff;
  }

  &:before {
    content: '';
    position: absolute;
    height: 1.5rem;
    width: 100%;
    top: -1.4rem;
    left: 0;
    background-position: center;
    background-image: url('../../assets/svg/Tooth.svg');
  }

  &::after {
    content: '';
    position: absolute;
    height: 1.5rem;
    width: 100%;
    bottom: -1.5rem;
    left: 0;
    background-position: center;
    background-image: url('../../assets/svg/Tooth2.svg');
  }
}

.content-container {
  width: 100%;
  max-width: 144rem;
  transition: all ease 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem 1.6rem;

  @include respondTo(tablet) {
    padding: 1.6rem 3.2rem;
  }
}

.app-banner {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  max-width: 100rem;
  width: 100%;
  gap: 2rem 4rem;

  h2 {
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;

    @include respondTo(tablet) {
      font-size: 4rem;
      text-align: left;
    }
  }

  p {
    text-align: center;

    @include respondTo(tablet) {
      font-size: 2rem;
      text-align: left;
    }
  }

  h3 {
    margin-bottom: 0.75rem;
    text-align: center;
    font-family: 'Agrandir-Narrow', sans-serif;
    font-weight: normal;

    @include respondTo(tablet) {
      font-size: 2.9rem;
      text-align: left;
    }

    > * {
      font-family: 'Agrandir-TextBold', sans-serif;
    }
  }

  .app-links {
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1rem 0 0;

    > a {
      width: 50%;
      max-width: 17rem;
      padding: 0 1rem 0 0;

      @include respondTo(tablet) {
        width: 100%;
      }
    }

    @include respondTo(tablet) {
      justify-content: flex-start;

      > a {
        width: 100%;
      }
    }

    button {
      padding: 0;
      width: 100%;
    }
  }

  img {
    flex: 0 0 25%;
    max-width: 30rem;
    min-width: 17rem;
    margin: 0 0 0 0;

    @include respondTo(tablet) {
      margin: 0 auto;
    }
  }
}
