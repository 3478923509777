.dateTimePicker {
  .pillActive {
    background-color: black;
    border-radius: 999px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pill {
    background-color: transparent;
    border-radius: 999px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pillText {
    color: black;
  }

  .pillTextActive {
    color: white;
  }
}
