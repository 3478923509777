@import '../../../../styles/variables';
@import '../../../../styles/breakpoints';

.activation-topbar {
  z-index: 5;
  .top {
    height: 7.2rem;
    .logo {
      display: none;
      cursor: pointer;
    }

    .mobileTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.8rem 2.2rem;

      .back {
        display: inline-block;
      }
    }
  }

  @include respondTo(tablet) {
    .top {
      background-color: $white;
      .logo {
        display: inline-block;
        padding: 1.7rem;
        padding-left: 3.2rem;
      }

      .mobileTop {
        display: none;
      }
    }
  }
}
