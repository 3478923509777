@use 'sass:map';
@import '../../styles/styles.scss';
@import '../../styles/variables';

.category-wrapper {
  &.desktop {
    position: relative;
    overflow: hidden;
    touch-action: none;

    &::before {
      pointer-events: none;
      content: '';
      opacity: 0;
      position: absolute;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      z-index: 9;
      height: 11.5rem;
      width: 5rem;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }

    &::after {
      pointer-events: none;
      opacity: 0;
      content: '';
      position: absolute;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      z-index: 9;
      height: 11.5rem;
      width: 5rem;
      bottom: 0;
      right: 0;
      transition: all 0.3s ease;
    }

    &.show-before::before {
      opacity: 1;
    }

    &.show-after::after {
      opacity: 1;
    }

    &.content {
      padding: 0;
      margin: 0 auto;
      width: calc(100% - 6.4rem);
      max-width: 137.6rem;
    }
    display: none;

    @include respondTo(tablet) {
      display: block;
    }

    .navigation {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 0 1.5rem 2rem 0;

      > * {
        margin: 0 1rem;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .items {
      display: flex;
      gap: 1rem;
      touch-action: none;

      &.is-dragging &.is-dragging * {
        cursor: grabbing;
      }
    }

    .items:not(.is-dragging) {
      transition: all 0.3s ease;
    }

    .item {
      user-select: none;
      height: 100%;
      display: block;
      min-width: 14.8rem;
      background-color: $white;
      border-radius: map.get($border-radius, 'rounded');
      border: map.get($border, 'default');
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1.6rem 1.6rem 0rem;

      &:hover {
        cursor: pointer;
      }

      img {
        flex: 1 1 auto;
        max-height: 5rem;
        pointer-events: none;
      }

      p {
        font-family: 'Agrandir-TextBold';
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
        text-align: center;
        margin: auto;
        text-transform: uppercase;
        padding-bottom: 0.5rem;
      }

      &.active {
        background-color: $black;

        p {
          color: $white;
        }
      }
    }
  }

  &.mobile {
    display: block;
    position: relative;
    overflow: visible;
    padding: 2rem 0;

    @include respondTo(tablet) {
      display: none;
    }

    .category-header {
      margin: 0 1.5rem;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.3rem;

      p {
        font-family: 'Agrandir-TextBold';
      }
    }

    .category-divider {
      width: stretch;
      height: 1px;
      margin: 0rem 1.6rem;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 25%,
        rgba(0, 0, 0, 0.1) 25%
      );
    }

    .modal-wrapper {
      padding: 2rem;
      width: 100%;
      position: absolute;
      overflow: hidden;
      transition: 0.3s ease;
      z-index: 10;

      .modal-categories {
        padding: 2rem 2.4rem;
        background-color: $white;
        width: 100%;
        border-radius: 1rem;
        border: map.get($border, 'default');
        overflow-y: scroll;
        max-height: 32.5rem;

        > p {
          margin-top: 0;
          color: $line-1;
          font-size: 1.25rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.05rem;
        }

        .items {
          display: flex;
          flex-direction: column;

          .item {
            height: 4rem;
            margin: 0.5rem 0;
            border-radius: 1rem;
            background-color: $white;
            border-radius: map.get($border-radius, 'rounded');
            border: map.get($border, 'default');
            font-family: 'Agrandir-TextBold';
            font-weight: bold;
            padding: 0 1rem;
            display: flex;
            align-items: center;

            img {
              width: 100%;
              max-width: 2.8rem;
              margin-right: 1rem;
            }

            p {
              font-family: 'Agrandir-TextBold';
              font-size: 1.3rem;
              line-height: 1.6rem;
              letter-spacing: 0.05rem;
              text-align: left;
              text-transform: uppercase;
              color: $black;
            }

            &.active {
              background-color: $black;

              p {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
