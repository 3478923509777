@import "../../../styles/breakpoints";

.hero-wrapper {
  width: 100%;
  margin-bottom: 4rem;
  display: grid;
  gap: 4rem;
  padding-top: 4rem;
  width: 100%;

  &.no-border {
    margin-bottom: 0rem;
  }

  .border {
    width: 100%;
    border-bottom: 0.1rem solid black;
  }

  @include respondTo(tablet) {
    gap: 8rem;
  }
}
