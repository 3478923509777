@import '../../styles/breakpoints';
@import '../../styles/styles.scss';

.special-accordion {
  overflow: hidden;

  .tab {
    overflow: hidden;
    margin-bottom: 3rem;

    @include respondTo(tablet) {
      margin-bottom: 0;
    }

    &-label {
      height: 6rem;
      font-size: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-bottom: 0.1rem solid $dropdown-underline;

      &::after {
        content: url('../../assets/svg/AccordionArrowDown.svg');
      }
    }
    &-content {
      max-height: 0;
      transition: all 0.35s ease;
      font-size: 1.7rem;
    }

    // :checked
    input {
      position: absolute;
      opacity: 0;
      z-index: -1;
      &:checked {
        + .tab-label {
          font-weight: bold;
          &::after {
            content: url('../../assets/svg/AccordionArrowUp.svg');
          }
        }
        ~ .tab-content {
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
          max-height: 100vh;
          transition: all 0.35s ease;
        }
      }
    }
  }
}
