@import '/src/styles/_variables.scss';

* {
  font-family: 'Agrandir', sans-serif;
  font-weight: normal;
}

div,
p {
  font-size: 1.4rem;
}

h1 {
  font-size: 3.2rem;
  font-weight: bold;
}

h2 {
  font-size: 2.4rem;
  font-weight: bold;
}

h3 {
  font-size: 1.872rem;
  font-weight: bold;
}

h4 {
  font-size: 1.6rem;
  font-weight: bold;
}

h5 {
  font-size: 1.328rem;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Agrandir-TextBold';
}

a {
  color: $sea-green;
}

input,
select,
span,
label {
  letter-spacing: 0.08rem;
}
