@use 'sass:map';
@import '../../styles/styles.scss';

.content {
  &.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: 100%;
    }

    *:not(span) {
      color: #ffffff;
    }
  }
}

.hello {
  font-size: 2rem;
  margin-top: 4rem;
  letter-spacing: 0.05em;
}

.scroll-to {
  display: flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  text-align: left;

  h2 {
    margin-right: 2rem;
    font-size: min(5.7vw, 2.4rem);
  }

  @include respondTo(tablet) {
    padding: 1rem 0 3rem;
  }

  img {
    height: min(12vw, 5rem);
    pointer-events: none;
    user-select: none;
  }
}

.values-container {
  margin: -1rem 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(tablet) {
    margin: 6rem 0 12rem;
  }

  > img {
    max-width: 100%;
    margin: 2rem 0 3rem;
    padding: 0 2rem;
  }

  > .values {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 2rem;

    > .value {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex: 0 1 32rem;
      padding: 0 2rem 2.5rem;
      text-transform: uppercase;

      > p {
        font-size: 1.9rem;
        font-family: 'Agrandir-TextBold';
      }

      > img {
        width: 6rem;
        margin-bottom: 0rem;

        @include respondTo(tablet) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.home-blurb-container {
  position: relative;
  overflow: hidden;

  @include respondTo(tablet) {
    overflow: visible;
  }

  &:before {
    content: '';
    position: absolute;
    top: -17%;
    left: -135%;
    width: 125%;
    height: 100%;
    transform: rotate(45deg);
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../assets/svg/home/BackgroundPattern.svg);
    z-index: 1;

    @include respondTo(mobile) {
      top: 10%;
      left: -40%;
      width: 50%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: min(30rem, 30vw);
    height: min(30rem, 30vw);
    right: -2%;
    bottom: 0;
    background-image: url(../../assets/svg/home/BackgroundShape.svg);
    z-index: 1;
  }
}

.home-blurb {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24rem 0 2rem;
  z-index: 2;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 25rem;
    right: 1rem;
    width: 5rem;
    height: 5rem;
    background-image: url(../../assets/svg/Stars4.svg);
    background-size: contain;
    background-repeat: no-repeat;

    @include respondTo(mobile) {
      display: none;
    }
  }

  @include respondTo(tablet) {
    padding: 15rem 0;
  }

  > * {
    text-align: center;
    max-width: 70rem;
    text-decoration: none;
  }

  > h2 {
    margin-bottom: 3rem;
    font-family: 'Agrandir-TextBold';
    font-weight: bold;
    font-size: 2.9rem;
    line-height: 4rem;
  }

  > a {
    width: 100%;

    @include respondTo(tablet) {
      width: 19rem;
    }
  }

  > .face {
    width: 10rem;
  }

  > .star {
    margin-top: 2.5rem;
    width: 2.5rem;
  }
}

.split-container {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  position: relative;
  z-index: 2;

  &.reverse {
    flex-wrap: wrap-reverse;
  }

  > * {
    flex: 1 0 50%;
    min-width: 30rem;

    @include respondTo(mobile) {
      min-width: 42rem;
    }
  }

  > .split-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    @include respondTo(tablet) {
      padding: 5rem;
    }

    > h2 {
      font-size: 4rem;
      font-family: 'Agrandir-TextBold';
    }

    > p {
      font-size: 2rem;
      line-height: 3rem;
      margin-top: 0;
      margin-bottom: 3rem;
      font-family: 'Agrandir-Narrow';
      letter-spacing: 0.05em;

      > span {
        font-family: 'Agrandir-TextBold';
      }
    }

    > .link {
      display: inline-block;
      text-decoration: none;

      @include respondTo(mobile) {
        width: 17rem;
      }
    }
  }

  > .split-image {
    display: flex;
    align-items: center;
    margin: 0 -2rem;

    @include respondTo(mobile) {
      margin: 0;
    }

    > * {
      width: 100%;
    }
  }
}

.breaker {
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  position: relative;

  > * {
    width: 7rem;

    @include respondTo(mobile) {
      width: 15rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 22%;
    right: -31%;
    width: 40%;
    height: 100%;
    transform: rotate(45deg) scale(2);
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../assets/svg/home/BackgroundPattern.svg);
    z-index: 1;

    @include respondTo(mobile) {
      transform: rotate(30deg) scale(2);
      top: 100%;
      right: -50%;
      width: 50%;
      height: 200%;
    }
  }
}

.home-footer {
  display: flex;
  padding-bottom: 10rem;
  justify-content: center;

  > * {
    margin-left: 50%;
  }
}

.stars-1 {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2.5rem;
    left: -4.5rem;
    width: 4rem;
    height: 4rem;
    background-image: url(../../assets/svg/Stars4.svg);
    background-size: contain;
    background-repeat: no-repeat;
    display: none;

    @include respondTo(tablet) {
      display: unset;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -2.5rem;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    transform: rotateY(180deg) rotateZ(180deg);
    background-image: url(../../assets/svg/Stars4.svg);
    background-size: contain;
    background-repeat: no-repeat;

    @include respondTo(tablet) {
      display: none;
    }
  }
}

.stars-2 {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2.5rem;
    left: -4.5rem;
    width: 4rem;
    height: 4rem;
    background-image: url(../../assets/svg/Stars3.svg);
    background-size: contain;
    background-repeat: no-repeat;
    display: none;

    @include respondTo(tablet) {
      display: unset;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -2.5rem;
    right: -1.5rem;
    width: 4rem;
    height: 4rem;
    transform: rotateY(180deg) rotateZ(180deg);
    background-image: url(../../assets/svg/Stars4.svg);
    background-size: contain;
    background-repeat: no-repeat;

    @include respondTo(tablet) {
      display: none;
    }
  }
}

div.our-mission-pad {
  margin-bottom: 5rem;
  overflow: hidden;
  @include respondTo(mobile) {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  @include respondTo(tablet) {
    margin-top: 0rem;
  }
}

.home-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2.4rem;
  column-gap: 2rem;

  @include respondTo(laptop) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 6rem;
    column-gap: 3rem;
  }

  > div {
    width: 100%;

    .card-image {
      width: 100%;
      border-radius: 1.5rem;
      box-shadow: unset;
    }
  }

  .details {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: 0.3s ease;
    }

    @include respondTo(tablet) {
      flex-direction: row;
      margin-top: 2rem;
      justify-content: space-between;
    }
  }

  .card {
    &:hover {
      cursor: pointer;

      @include respondTo(tablet) {
        .price-wrapper {
          transform: translateX(-3rem);
        }
      }
    }
    .details {
      overflow: hidden;
    }
    .price-wrapper {
      display: flex;
      transition: 0.3s ease;

      img {
        display: none;
        filter: invert(1);
      }

      @include respondTo(tablet) {
        transform: translateX(1.5rem);

        img {
          display: block;
          display: block;
          width: 2rem;
          position: relative;
          bottom: 0.1rem;
          right: -1rem;
          transform: translateX(2rem);
        }
      }
    }
    .price {
      color: $white;
      white-space: nowrap;
      text-align: left;
    }
  }
}

.home.cards-container {
  margin-bottom: 5rem;
}

.card,
.card * {
  transition: all 0.3s ease;
}

.brand-section {
  margin: 9.5rem 0;
  margin-bottom: 0rem;
  .content.brand-section-header {
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-title {
      font-size: min(4rem, 9vw);
      line-height: min(4.8rem, 10vw);
      font-weight: 700;
      text-align: center;
      color: $white;
      position: relative;
      max-width: calc(100% - 13rem);
      min-width: min(35rem, 84vw);
      @include respondTo(tablet) {
        max-width: 75rem;
        font-size: 4rem;
        line-height: 4.8rem;
        min-width: unset;
      }
    }
  }

  .brand-small-stars {
    position: absolute;
    top: max(-5rem, -8vw);
    left: max(-4.5rem, -4vw);
    width: min(7.5rem, 10vw);
    @include respondTo(tablet) {
      left: max(-6.5rem, -4vw);
      top: -5rem;
    }
  }

  .content.brand-section-footer {
    margin-top: 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .brand-button {
      display: inline-block;
      margin: 0 auto;
      width: 100%;
      text-decoration: none;
      @include respondTo(tablet) {
        max-width: 32rem;
      }
    }
    .brand-affiliation-text {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $white;
      text-align: center;
      margin-top: 3rem;
      margin-bottom: 2rem;
      @include respondTo(tablet) {
        margin-top: 4rem;
      }
    }
  }
  @include respondTo(tablet) {
    margin: 14rem 0;
    margin-bottom: 0rem;
  }
}
