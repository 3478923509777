.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;

  .loader {
    animation: loader-rotate 2s cubic-bezier(.77,0,.18,1) infinite;
    margin: 0 auto;
    width: 7.3rem;
    height: 7.3rem;
  }
  p {
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: 2rem;
  } 
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}