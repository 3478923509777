@import '../../styles/styles.scss';

.special-error-text {
  padding: 0.5rem 0rem 1.5rem 0rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 0.04em;
  color: $red;
  max-width: 334px;
}
