@import '../../../styles/breakpoints';

.purchase-personalise {
  .customiseInputItem {
    margin-bottom: 2rem;

    .special-error-text {
      padding-bottom: 0;
    }

    .messageCount {
      font-size: 1rem;
      line-height: 1.4rem;
      text-align: right;
      color: #778899;
      margin-top: 0.4rem;
    }
  }
  .special-alert {
    width: unset;
    height: unset;
  }

  @include respondTo(tablet) {
    .special-alert {
      margin-bottom: 4rem;
    }
  }
}
