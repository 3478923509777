@import '../../../../styles/breakpoints';

.valuesGrid {
  --grid-layout-gap: 1.2rem;
  --grid-column-count: 3;
  --grid-item--min-width: 9.8rem;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  margin-bottom: 2rem;
  grid-gap: var(--grid-layout-gap);
  @include respondTo(tablet) {
    max-width: 32rem;
  }
}
