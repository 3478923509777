@import '../../styles//variables';
@import '../../styles/breakpoints';

.downloadApp {
  padding: 3rem 0;
  background-color: $white;
  border: 0.2rem solid $black;
  border-radius: 0.9rem;
  overflow: hidden;
  .qrCode {
    display: none;
  }

  .middleText {
    display: none;
  }

  .appButton {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .appButtonWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  @include respondTo(tablet) {
    background-color: $white;
    padding: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    .qrCode {
      padding: 1rem;
      border: 0.2rem solid $black;
      border-radius: 0.9rem;
      display: block;
    }

    .middleText {
      display: block;
      margin: 0 1.5rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .appButton {
      .appButtonWrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        & > button {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}
