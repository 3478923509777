@import "../../../styles/styles.scss";
@import '../../../styles/breakpoints';

.subtitle {
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  margin-left: 0;
  text-align: right;
  max-width: 100%;
  text-align: left;
  margin-bottom: 1.5rem;

  @include respondTo(tablet) {
    font-size: 3.2rem;
    text-align: right;
    margin-bottom: 4.5rem;
  }

  &.inverse {
    color: white;
  }
}

.how-it-works-subtitle p,
.our-mission .subtitle,
.contact .subtitle {
  font-family: "Agrandir-TextBold", sans-serif;
  letter-spacing: 0.04em;

  > span {
    white-space: nowrap;
    font-family: "Agrandir-TextBold", sans-serif;
    letter-spacing: 0.04em;

    > span {
      font-family: "Agrandir-TextBold", sans-serif;
      font-size: 2rem;
      letter-spacing: 0.04em;
      display: inline-block;
      transform: translateY(-0.3rem);
  
      @include respondTo(tablet) {
        transform: translateY(-0.9rem);
      }
    }
  }
}

.our-mission .subtitle {
  @media screen and (min-width: 1320px) {
    transform: translateY(-9.5rem);
    margin-bottom: -4rem;
  }
}