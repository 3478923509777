@import '../../../../styles/variables';
@import '../../../../styles/breakpoints';

.orderSummary {
  .section {
    border: 0.1rem solid $whiteStickerBorderColor;
    margin: 0 3rem;
    border-radius: 0.8rem;
    .topSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.4rem 3rem;
      border-bottom: 1px solid $whiteStickerBorderColor;
      .left {
        display: flex;
        flex-direction: column;

        .sectionTitle {
          font-size: 1.3rem;
          line-height: 1.7rem;
          font-weight: bold;
          letter-spacing: 0.1rem;
        }

        .sectionValue {
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }

      .right {
        .smallCard {
          width: 6.7rem;
          height: 4rem;
          border-radius: 0.4rem;
        }
        .brandCard {
          width: 4rem;
          height: 4rem;
          border-radius: 0.4rem;
        }
      }
    }

    .bottomSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.4rem 3rem;
      .left {
        .sectionTitle {
          font-size: 1.3rem;
          line-height: 1.7rem;
          font-weight: bold;
          letter-spacing: 0.1rem;
        }
      }

      .right {
        font-size: 1.7rem;
        line-height: 2.4rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
      }
    }
  }

  @include respondTo(tablet) {
    .section {
      .topSection {
        .left {
          flex-direction: row;
          align-items: center;

          .sectionTitle {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
