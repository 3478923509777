@import '../../styles/breakpoints';

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2.4rem;
  column-gap: 2rem;

  @include respondTo(laptop) {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8rem;
    column-gap: 3rem;
  }

  > div {
    width: 100%;

    .card-image {
      width: 100%;
      border-radius: 1.5rem;
      transition: all 0.5s ease;

      @include respondTo(laptop) {
        box-shadow: 0 1rem 1rem 0 #eaeaea;
      }
    }
  }

  &.spacer {
    padding-top: 8rem;
  }

  .details {
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 1.5rem;
      transition: 0.3s ease;
      font-weight: bold;
      text-transform: uppercase;
    }

    @include respondTo(tablet) {
      flex-direction: row;
      margin-top: 2rem;
      justify-content: space-between;
    }
  }

  .card {
    &:hover {
      cursor: pointer;
      position: relative;

      @include respondTo(tablet) {
        .price-wrapper {
          transform: translateX(-3rem);
        }
      }
    }

    .details {
      overflow: hidden;
    }
    .price-wrapper {
      display: flex;
      transition: transform 0.3s ease;

      img {
        display: none;
      }

      @include respondTo(tablet) {
        transform: translateX(1.5rem);

        img {
          display: block;
          width: 2rem;
          position: relative;
          bottom: 0.1rem;
          right: -1rem;
          transform: translateX(2rem);
        }
      }
    }
    .price {
      color: #728291;
      white-space: nowrap;
      text-align: left;

      @include respondTo(tablet) {
        color: black;
      }
    }
  }
}

.card {
  position: relative;
  &:hover {
    .card-image-container img {
      transform: scale(1.02);
      animation: hoverShimmer 0.5s ease;
    }
  }
}

@keyframes hoverShimmer {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(1);
  }
}
