@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.activationSuccess {
  background-color: $sea-green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .successCardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 1.6rem;
    position: relative;
    top: -3rem;
  }
  .scallop {
    position: relative;
    top: 1.4rem;
  }

  .details {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    position: relative;
    top: -2.1rem;
    margin-bottom: -3.5rem;
    .detailsWrapper {
      margin: 0 auto;
      padding: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .title {
        font-size: 2.9rem;
        line-height: 4.1rem;
        font-family: 'Agrandir-TextBold';
        text-align: center;
        margin-bottom: 4rem;
      }

      .subTitle {
        font-size: 1.7rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3rem;
      }

      .specialIcon {
        margin-bottom: 3rem;
      }

      .successDownloadApp {
        margin-bottom: 4rem;
        border: none;
        width: 100%;
      }

      .seeGift {
        width: 100%;
        margin-bottom: 5rem;
      }

      .shop {
        display: flex;
        flex-direction: column;
        margin-bottom: 7.9rem;
        .shopItem {
          margin-bottom: 3rem;

          .itemTitle {
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: bold;
            margin-bottom: 2rem;
          }

          .itemSubTitle {
            font-size: 1.7rem;
            line-height: 2.4rem;
            .myCardsLink {
              color: $black;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @include respondTo(tablet) {
    .successCardWrapper {
      margin-top: 8rem;
      margin-bottom: 1.5rem;
      top: 0rem;
    }
    .scallop {
      position: relative;
      top: 68px;
    }
    .details {
      margin-top: 2rem;
      top: 1.3rem;
      margin-bottom: 0rem;
      .detailsWrapper {
        max-width: 60rem;

        .subTitle {
          margin-bottom: 3.5rem;

          .thick {
            font-weight: bold;
          }
        }

        .specialIcon {
          margin-bottom: 3.5rem;
        }

        .successDownloadApp {
          margin-bottom: 5.3rem;
        }

        .seeGift {
          max-width: 31rem;
        }

        .shop {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4.7rem;
          .shopItem {
            margin-right: 2rem;
          }
        }
      }
    }
  }
}
