@import '../../../styles//variables';
@import '../../../styles/breakpoints';

.activationFailed {
  .topBar {
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3.2rem;
  }

  .failedArt {
    img {
      height: 35rem;
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: left;
    }
  }

  .scallop {
    position: relative;
    top: -35px;
  }
  .details {
    margin-top: -3.5rem;
    padding: 0 3rem;
    .title {
      white-space: pre-line;
      font-size: 2.9rem;
      line-height: 4.1rem;
      text-align: center;
      font-family: 'Agrandir-TextBold';
      margin-bottom: 2rem;
    }

    .subTitle {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 6rem;
      text-align: center;
    }

    .summary {
      margin-bottom: 5rem;
    }

    .contactButton {
      margin: 0 auto;
      max-width: 21.7rem;
      margin-bottom: 10.3rem;
    }
  }
  @include respondTo(tablet) {
    .details {
      max-width: 60rem;
      margin: 0 auto;
      margin-top: 0.5rem;
      .title {
        margin-bottom: 2.6rem;
      }

      .subTitle {
        margin-bottom: 8.4rem;
      }
      .summary {
        margin-bottom: 7.5rem;
      }

      .contactButton {
        margin-bottom: 11.5rem;
      }
    }
  }
}
