$mobileWidth: 576px;
$tabletWidth: 768px;
$laptopWidth: 1024px;
$desktopWidth: 1440px;

@mixin respondTo($device) {
  @if $device == mobile {
    @media (min-width: $mobileWidth) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: $tabletWidth) {
      @content;
    }
  } @else if $device == laptop {
    @media (min-width: $laptopWidth) {
      @content;
    }
  } @else if $device == desktop {
    @media (min-width: $desktopWidth) {
      @content;
    }
  } @else {
    @warn "respondTo mixin supports: tablet, laptop, desktop";
  }
}
