@import '../../../styles/styles.scss';
@import '../../../styles/breakpoints';

.landing {
  background-color: $electric-blue;
  width: 100%;

  .logo {
    padding: 3.2rem 3rem;
  }

  .scallop {
    position: relative;
    top: 3.5rem;
  }

  .container {
    max-width: 100%;
    position: relative;
    padding: 0 5rem;
    .landingGift {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 36.6rem;
      height: auto;
    }

    .wording {
      margin-top: 2.2rem;
      font-family: 'Agrandir-TextBold';
      font-size: 2.8rem;
      color: $white;
      padding-bottom: 1.7rem;
      word-break: break-word;
      .receiver {
        font-size: 2.8rem;
        margin-bottom: 3.8rem;
        font-weight: bold;
        line-height: 3.8rem;
      }

      .message {
        line-height: 3.8rem;
        font-size: 2.8rem;
        margin-bottom: 3.8rem;
      }

      .gifter {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .heart {
          margin-right: 1.5rem;
        }

        .gifterText {
          font-size: 2.8rem;
          font-weight: bold;
          line-height: 3.8rem;
          font-weight: bold;
        }
      }
    }
  }

  .activation-unwrap {
    background-color: $white;
    text-align: left;
    padding-top: 6.2rem;
    padding-bottom: 8.7rem;
    display: block;

    .title {
      font-family: 'Agrandir-TextBold';
      font-size: 2.4rem;
      line-height: 3.3rem;
      margin-bottom: 3rem;

      // remove after DownloadApp
      padding-right: 5rem;
    }

    .subTitle {
      font-size: 2rem;
      line-height: 2.8rem;
      margin-bottom: 3rem;
    }

    .buttonWrapper {
      display: block;
      margin: 0 auto;

      // remove after DownloadApp
      margin-bottom: 2.2rem;
    }

    .downloadSection {
      margin: 2.2rem 0;
      .downloadText {
        text-align: start;
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.8rem;
        font-family: Arial;
        margin-bottom: 1rem;
      }
    }

    .divider {
      margin-top: 2.2rem;
    }

    // add for DownloadApp
    // .star1 {
    //   position: absolute;
    //   right: 1.5rem;
    //   top: 20%;
    //   display: inline;
    // }

    // .star2 {
    //   position: absolute;
    //   top: 0;
    //   right: 3rem;
    //   display: inline;
    // }

    // remove after DownloadApp
    .star1 {
      position: absolute;

      right: max(1vw, 1rem);
      top: max(-25vw, -5.5rem);
      display: inline;
    }

    .star2 {
      position: absolute;
      top: max(-3vw, -1rem);
      right: min(7vw, 2.5rem);
      display: inline;
    }
  }

  @include respondTo(tablet) {
    .logo {
      padding: 4rem;
      padding-bottom: 2rem;
    }

    .container {
      max-width: 60rem;
      margin: 0 auto;
      padding: 0;
      .landingGift {
        max-width: 55rem;
      }
      .wording {
        margin-top: 2.5rem;
      }
    }

    .activation-unwrap {
      .title {
        margin-bottom: 2rem;
        text-align: center;

        // remove after DownloadApp
        padding-right: 0;
      }

      .subTitle {
        margin-bottom: 4.8rem;
        text-align: center;
      }

      .buttonWrapper {
        max-width: 35rem;

        // remove after DownloadApp
        margin-bottom: 4.8rem;
      }

      .downloadSection {
        margin: 4.8rem 0;
      }

      .divider {
        display: none;
      }
      .star1 {
        position: absolute;
        right: -10%;
        top: 0;
        display: inline;
      }

      .star2 {
        position: absolute;
        top: 33%;
        right: 0;
        display: inline;
      }
    }
  }
  .ps {
    font-size: 1.4rem;
    line-height: 1.9rem;
    text-align: start;
  }

  .isBold {
    font-weight: bold;
  }
}

.activationLandingLoading {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  background-color: $electric-blue;
  display: flex;
  align-items: center;
  justify-content: center;
}
