@import '_breakpoints.scss';
@import '_typography.scss';
@import '_variables.scss';
@import '_miscellaneous.scss';
@import '_shared.scss';

@font-face {
  font-family: 'Agrandir';
  src: local('Agrandir-Narrow'),
    url(../assets/fonts/Agrandir-Narrow.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Agrandir';
  src: local('Agrandir-TextBold'),
    url(../assets/fonts/PPAgrandir-TextBold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Agrandir-Narrow';
  src: local('Agrandir-Narrow'),
    url(../assets/fonts/Agrandir-Narrow.otf) format('opentype');
}

@font-face {
  font-family: 'Agrandir-TextBold';
  src: local('Agrandir-TextBold'),
    url(../assets/fonts/PPAgrandir-TextBold.otf) format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Agrandir-GrandHeavy';
  src: local('Agrandir-GrandHeavy'),
    url(../assets/fonts/Agrandir-GrandHeavy.otf) format('opentype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Kobe';
  src: local('Kobe-Regular'),
    url(../assets/fonts/Kobe-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Kobe-Bold';
  src: local('Kobe-Bold'), url(../assets/fonts/Kobe-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Kobe-Black';
  src: local('Kobe-Black'),
    url(../assets/fonts/Kobe-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'diamond';
  src: url('../assets/fonts/diamond/diamond.eot?82345841');
  src: url('../assets/fonts/diamond/diamond.eot?82345841#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/diamond/diamond.woff2?82345841') format('woff2'),
    url('../assets/fonts/diamond/diamond.woff?82345841') format('woff'),
    url('../assets/fonts/diamond/diamond.ttf?82345841') format('truetype'),
    url('../assets/fonts/diamond/diamond.svg?82345841#diamond') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  letter-spacing: 0.03em;
}
html {
  font-size: 10px;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: $applicationBackground;
}
a {
  color: $electric-blue;
}

.main-content {
  min-height: calc(100vh - 40rem);
}

//for demo
#demo {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;

  @include respondTo(laptop) {
    flex-direction: row;
  }
}

#demo > * {
  margin-top: 30px;
}

#demo-buttons {
  @include respondTo(laptop) {
    width: 33%;
  }
  background-color: #ffffff;
}

#white-font-buttons {
  background-color: #000000;
  padding: 5px 0;
}

#white-font-buttons > * {
  margin: 10px 20px;
}

#demo-buttons > * {
  margin: 20px;
}

#demo-various-1 {
  background-color: #ffffff;
  @include respondTo(laptop) {
    width: 29%;
  }
}
#demo-various-2 {
  background-color: #ffffff;
  @include respondTo(laptop) {
    width: 38%;
  }
}
#demo-various-1 > *,
#demo-various-2 > * {
  margin: 20px;
}

#tabs {
  display: flex;
  flex-direction: row;
}

h3 {
  color: #ffa500;
}

form > div {
  padding: 5px 0;
}

// flex box utilities classes
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
