@import '../../styles/breakpoints';

.image-wrapper {
  .desktop {
    display: none;
    width: 100%;

    @include respondTo(mobile) {
      display: block;
    }
  }

  .mobile {
    display: block;
    width: 100%;
    border-radius: 2rem;

    @include respondTo(mobile) {
      display: none;
    }
  }
}
