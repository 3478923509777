@use 'sass:map';
@import '../../styles/styles.scss';

.special-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1.9rem;
  text-transform: uppercase;
  border: 0.1rem solid $black;
  border-radius: 0.8rem;
  width: 100%;
  letter-spacing: 0.04em;
  font-weight: bold;

  > * {
    font-weight: 700;
  }

  &.black {
    background-color: $black;
    color: $white;
  }

  &.white {
    background-color: #ffffff;
    color: $black;

    > .chevron-down-icon {
      background-image: url('../../assets/svg/ChevronDownBlack.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.transparent {
    background-color: transparent;
    color: $white;
  }

  &:hover {
    border: 0.2rem solid $sea-green;
    color: $sea-green !important;
    cursor: pointer;
    > .chevron-down-icon {
      background-image: url('../../assets/svg/ChevronDownGreen.svg');
    }
  }

  &.active > * {
    font-weight: bold !important;
    color: $sea-green !important;
  }

  &.primary {
    height: 6.6rem;
    font-size: 1.5rem;
    padding: 2rem 3.4rem 1.8rem 2.8rem;
  }

  &.secondary {
    transition: all ease 0.3s;
    height: 5rem;
    padding: 1.6rem 2.8rem 1.4rem;

    &.link {
      padding: 0rem;
      border: transparent;
      background-color: transparent;

      span {
        font-weight: bold;
      }
    }
  }

  &.tertiary {
    font-size: 1.3rem;
    height: 3.1rem;
    padding: 0.7rem 1.4rem 0.7rem 1rem;
  }

  &.ghost {
    padding: 1.6rem 2.8rem 1.4rem;
    border: 0.1rem solid $white;
    height: 5.1rem;
    fill: $white;
    stroke: $white;

    &:hover {
      background: $white;
      border: 0.1rem solid $black;
      color: $black !important;
    }
  }

  &.apple-pay {
    > .apple-pay-icon {
      height: 2.4rem;
      width: 5.9rem;
      background-image: url('../../assets/svg/ApplePayWhite.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover {
      > .apple-pay-icon {
        background-image: url('../../assets/svg/ApplePayGreen.svg');
      }
    }
    &.primary {
      height: 7rem;
    }

    &.secondary {
      height: 5.4rem;
    }

    &.tertiary {
      height: 3.8rem;
    }

    &.white {
      > .apple-pay-icon {
        background-image: url('../../assets/svg/ApplePayBlack.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
      &:hover {
        > .apple-pay-icon {
          background-image: url('../../assets/svg/ApplePayGreen.svg');
        }
      }
    }
  }

  &.browse {
    height: 4.7rem;
    padding: 1.6rem 2.8rem 1.4rem;
    font-size: 1.3rem;
    line-height: 1.7rem;
    letter-spacing: 0.05em;
    border: 0;
    border-radius: 0;

    > .right-arrow-icon {
      margin-left: 0.9rem;
    }

    > .left-arrow-icon {
      margin-right: 0.9rem;
    }

    &:hover {
      color: $black !important;
    }

    &.black:hover {
      color: $white !important;
    }
  }

  &.cart {
    border: 0;
    border-radius: 0;

    > * {
      background-position: center;
      background-repeat: no-repeat;
    }

    > .cart-empty-icon {
      height: 3rem;
      width: 3rem;
      background-image: url('../../assets/svg/CartEmpty.svg');
    }

    > .cart-item-icon {
      height: 3.4rem;
      width: 3.4rem;
      background-image: url('../../assets/svg/CartItem.svg');
    }
  }

  &.mobile-menu {
    border: 0;
    border-radius: 0;
    justify-content: flex-start;

    > * {
      height: 3rem;
      width: 3rem;
      background-position: center;
      background-repeat: no-repeat;
    }

    > .mobile-menu-icon-black {
      background-image: url('../../assets/svg/MobileMenuBlackIcon.svg');
    }

    > .mobile-menu-icon-white {
      background-image: url('../../assets/svg/MobileMenuWhiteIcon.svg');
    }

    > .mobile-menu-icon-active {
      background-image: url('../../assets/svg/MobileMenuActiveIcon.svg');
    }
  }

  &.app-store {
    border-radius: 0.6rem;
    border: 0;
    width: unset;

    > * {
      background-size: contain;
      height: 5.2rem;
      width: 15rem;

      @include respondTo(tablet) {
        width: 16.9rem;
      }
      background-position: center;
      background-repeat: no-repeat;
    }

    > .google-play-black-icon {
      background-image: url('../../assets/svg/GooglePlayBlack.svg');
    }

    > .google-play-white-icon {
      background-image: url('../../assets/svg/GooglePlayWhite.svg');
    }

    > .apple-store-black-icon {
      background-image: url('../../assets/svg/AppleStoreBlack.svg');
    }

    > .apple-store-white-icon {
      background-image: url('../../assets/svg/AppleStoreWhite.svg');
    }
  }

  &.social {
    height: 5.4rem;
    border: 0.1rem solid $socialBorderColor;
    border-radius: 0.8rem;

    > * {
      height: 2.4rem;
      width: 2.4rem;
      background-position: center;
      background-repeat: no-repeat;
    }

    > .facebook-icon {
      background-image: url('../../assets/svg/FacebookLogo.svg');
    }

    > .google-icon {
      background-image: url('../../assets/svg/GoogleLogo.svg');
    }

    > .apple-icon {
      background-image: url('../../assets/svg/AppleLogo.svg');
    }
  }

  &.sticker {
    flex-direction: column;
    width: 14.8rem;
    height: 11.2rem;
    padding: 1.6rem 1.6rem 1.2rem;
    border-radius: 0.9rem;
    font-size: 1.3rem;
    line-height: 1.6rem;

    > * {
      height: 5.3rem;
      width: 5.3rem;
      background-position: center;
      background-repeat: no-repeat;
    }

    > .sticker-icon {
      background-image: url('../../assets/svg/StickerLight.svg');
      margin-bottom: 1rem;
    }

    &.white {
      background: $applicationBackground;
      border: 0.1rem solid $whiteStickerBorderColor;

      > .sticker-icon {
        background-image: url('../../assets/svg/Sticker.svg');
      }
    }
  }

  &.icon-link {
    width: max-content;
    height: 6.6rem;
    background-color: transparent;
    border-radius: 0.5rem;
    letter-spacing: 0.04em;

    &.right {
      padding: 2.8rem;
    }

    &.left {
      padding: 2.8rem 3.4rem 2.8rem 2rem;
    }

    > * {
      background-position: center;
      background-repeat: no-repeat;
    }

    > .right-heart-icon {
      margin-left: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/Heart.svg');
    }

    > .left-heart-icon {
      margin-right: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/Heart.svg');
    }

    > .left-heart-purple-icon {
      margin-right: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/HeartPurple.svg');
    }

    > .right-heart-purple-icon {
      margin-left: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/HeartPurple.svg');
    }

    > .left-flower-icon {
      margin-right: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/Flower.svg');
    }

    > .right-flower-icon {
      margin-left: 1.2rem;
      height: 2.1rem;
      width: 2.3rem;
      background-image: url('../../assets/svg/Flower.svg');
    }

    > .cart-empty-icon {
      margin-right: 1rem;
      height: 3rem;
      width: 3rem;
      background-image: url('../../assets/svg/CartEmpty.svg');
    }

    > .cart-item-icon {
      margin-right: 1rem;
      height: 3.4rem;
      width: 3.4rem;
      background-image: url('../../assets/svg/CartItem.svg');
    }
  }

  &.store-search {
    padding: 0.8rem 2rem 0.8rem 1.6rem;
    height: 6.1rem;
    border: map.get($border, 'default');
    border-radius: 0.9rem;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.1rem;
    text-transform: none;
  }

  > .heart-icon {
    margin-right: 1.5rem;
    height: 1.6rem;
    width: 1.7rem;
    background-image: url('../../assets/svg/Heart.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .red-heart-icon {
    margin-right: 1.5rem;
    height: 3rem;
    width: 3rem;
    background-image: url('../../assets/svg/RedHeart.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .orange-heart-icon {
    margin-right: 1.5rem;
    height: 1.6rem;
    width: 1.7rem;
    background-image: url('../../assets/svg/HeartOrange.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .right-arrow-icon {
    margin-left: 1.4rem;
    height: 2.1rem;
    width: 2.1rem;
    background-image: url('../../assets/svg/RightArrowWhite.svg');
    background-position: center;
    background-repeat: no-repeat;

    &.blue {
      background-image: url('../../assets/svg/RightArrowBlue.svg');
    }
  }

  > .left-arrow-icon {
    margin-left: 1.4rem;
    height: 2.1rem;
    width: 2.1rem;
    background-image: url('../../assets/svg/LeftArrowWhite.svg');
    background-position: center;
    background-repeat: no-repeat;

    &.blue {
      background-image: url('../../assets/svg/LeftArrowBlue.svg');
    }
  }

  > .chevron-down-icon {
    margin-left: 1rem;
    width: 1.2rem;
    height: 0.6rem;
    background-image: url('../../assets/svg/ChevronDownWhite.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .chevron-right-icon {
    margin-left: 0.8rem;
    width: 1.2rem;
    height: 2rem;
    background-image: url('../../assets/svg/ChevronRight.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .arrow-right-icon {
    margin-left: 1.8rem;
    width: 2rem;
    height: 2rem;
    background-image: url('../../assets/svg/ArrowRight.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .flower-icon {
    margin-right: 0.8rem;
    width: 3.2rem;
    height: 3.2rem;
    background-image: url('../../assets/svg/Flower.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  > .present-icon {
    margin-right: 0.8rem;
    width: 3.2rem;
    height: 3.2rem;
    background-image: url('../../assets/svg/Present2.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  &.light-border {
    border: 0.1rem solid #cbd0d6;
  }

  &.split {
    .button-title {
      width: 100%;
      text-align: left;
    }
  }

  &.no-case {
    .button-title {
      text-transform: none;
      font-weight: 400;
    }
  }
}
