@import '../../styles/variables';
@import '../../styles/breakpoints';

.my-cards-container {
  .swiper-pagination-bullet-active {
    background-color: #000;
  }

  .noCardBackground {
    background: radial-gradient(circle, $soft-lilac 36%, $white 36%);
    background-repeat: repeat-x;
    background-size: 7.9rem 21.2rem;
    background-position: top;
    content: '';
    display: block;
    position: fixed;
    top: calc(65% + 1px);
    left: 0;
    height: 10.5rem;
    width: 100%;
    @include respondTo(tablet) {
      top: calc(40% + 1px);
    }
    &::after {
      background: $soft-lilac;
      position: fixed;
      content: '';
      bottom: 0;
      height: calc(35% - 10.5rem);
      width: 100vw;
      @include respondTo(tablet) {
        height: calc(60% - 10.5rem);
      }
    }

    .noCardImage {
      position: fixed;
      height: 55vh;
      bottom: -7vh;
      left: -20vw;
      z-index: 10;

      @include respondTo(tablet) {
        height: calc(100vh - 8rem);
        bottom: 0;
        left: 45vw;
      }
    }
  }

  .no-card-content {
    position: fixed;
    text-align: center;
    padding: 2.8rem;
    .content-title {
      font-weight: bold;
      font-size: 2.9rem;
      margin-top: 4rem;
    }

    .content-text {
      font-size: 2rem;
      margin-top: 1rem;
      line-height: 2.8rem;
    }

    button {
      height: unset;
      padding: 1rem 2rem;
      margin: 0 auto;
      width: auto;
    }
  }
}
