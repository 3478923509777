@import '../../../styles/breakpoints';

.fixed-col-wrapper {
  padding-top: 0rem;
  display: flex;
  flex-direction: column;

  @include respondTo(tablet) {
    flex-direction: row;
  }

  > :last-child {
    @include respondTo(laptop) {
      top: 2rem;
      position: sticky;
      max-height: 100vh;
    }
  }

  &.inverse {
    > :first-child {
      top: 2rem;
      position: sticky;
      max-height: 100vh;
    }

    &.hide-mobile-content {
      > :last-child {
        display: none;

        @include respondTo(laptop) {
          display: block;
        }
      }
    }
  }

  &.no-stick > div,
  &.no-stick > div:first-child {
    position: unset;
    max-height: none;
  }

  b {
    font-weight: bold;
  }
}

.hide-mobile-content {
  > :last-child {
    display: none;

    @include respondTo(laptop) {
      display: block;
    }
  }
}
