$sea-green: #00cca8;
$orange: #ffa500;
$red: #ff4500;
$electric-blue: #4200ff;
$soft-lilac: #e9c0ff;
$applicationBackground: #fff;
$black: #000000;
$white: #ffffff;
$border-radius: (
  'rounded-sm': 0.4rem,
  'rounded': 0.9rem,
  'rounded-full': 50%,
);
$options-background-hover: rgba(218, 214, 231, 0.534);
$border: (
  'default': solid 0.1rem #cbd0d6,
  'selected': solid 0.2rem $black,
  'error': solid 0.1rem $red,
  'error-selected': solid 0.2rem $red,
  'success': solid 0.1rem $sea-green,
);
$tag-colors: (
  'red': $red,
  'orange': $orange,
  'green': $sea-green,
);
$dropdown-underline: #728291;
$alert-background-colors: (
  'success': rgba(0, 204, 168, 0.05),
  'info': rgba(66, 0, 255, 0.05),
  'warning': rgba(255, 165, 0, 0.05),
  'error': rgba(255, 69, 0, 0.05),
);
$alert-border-color: (
  'success': rgba(0, 204, 168, 0.3),
  'info': rgba(66, 0, 255, 0.2),
  'warning': rgba(255, 165, 0, 0.4),
  'error': rgba(255, 69, 0, 0.2),
);
$socialBorderColor: #dad6e7;
$whiteStickerBorderColor: #cbd0d6;
$tabActiveBoxShadow: rgba(0, 0, 0, 0.15);
$tabDisabledColor: #728291;
$passwordComplexityBorderColor: (
  'default': #cbd0d6,
  'error': $red,
  'fair': $orange,
  'good': $sea-green,
  'excellent': $sea-green,
);
$tooltipBoxShadowColor: rgba(0, 0, 0, 0.05);
$mobileInputPlaceholderColor: #728291;
$mobileInputBorderColor: #cbd0d6;
$line-1: #728291;
$line-2: #cbd0d6;
$counter-color: #778899;
$midnight-blue: #001f73;
$bone: #fffaf0;
