@use 'sass:map';
@import '../../styles/styles.scss';

.special-money-input {
  display: flex;
  position: relative;

  .label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.3rem 0.2rem;
    margin: 1rem 1.5rem;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: $white;
    transition: transform 120ms ease-in;
    font-size: 1.6rem;

    &.error {
      color: $red;
    }
  }

  .field {
    outline: none;
    box-sizing: border-box;
    display: block;
    width: 100% !important;
    border: map.get($border, 'default');
    padding: 1.2rem 3.2rem;
    color: $black;
    background: $white;
    border-radius: map.get($border-radius, 'rounded');
    font-size: 1.6rem;
    letter-spacing: 0.04em;

    &::-webkit-inner-spin-button {
      margin-right: -15px;
    }

    -moz-appearance: textfield;
    appearance: textfield;

    @include respondTo(tablet) {
      // width: 33.6rem !important;
    }

    &.error {
      border: map.get($border, 'error');
      color: $red;
    }

    // when not focused
    &:not(:placeholder-shown) {
      &.error {
        border: map.get($border, 'error');
      }

      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $black;
        &.error {
          color: $red;
        }
      }
    }

    &:focus {
      border: map.get($border, 'selected');

      &.error {
        border: map.get($border, 'error-selected');
      }

      & + .label {
        transform: translate(-0.1rem, -75%) scale(0.8);
        padding: 0.1rem 0.5rem 0.1rem 0.6rem;
        border-radius: map.get($border-radius, 'rounded-sm');
        color: $white;
        background-color: $electric-blue;
        &.error {
          background: linear-gradient(
              0deg,
              map.get($alert-border-color, 'error'),
              map.get($alert-border-color, 'error')
            ),
            $white;
          color: $black;
        }
      }
    }
  }
  .dollar-sign {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 2rem;
    font-size: 1.6rem;

    &.error {
      color: $red;
    }
  }
}
