@import '../../styles/breakpoints';

.contact-container {
  margin-top: 6rem;
  max-width: 56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 12em;

  > img {
    max-width: 8rem;
  }
}

.contact-input {
  width: 100%;
  margin-bottom: 1.4rem;
  @include respondTo(tablet) {
    margin-bottom: 2rem;
  }
}

.contact-title {
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 2.5rem;
  text-transform: uppercase;
  margin-bottom: 2.8rem;
}
