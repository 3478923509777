@use 'sass:map';
@import '../../styles/styles.scss';

.special-password-complexity {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 2.1rem;
  padding: 0rem;
  padding-bottom: 4rem;


  > .lines {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    > * {
      width: 6.52rem;
      @include respondTo(tablet) {
        width: 7.24rem;
      }
      height: 0rem;
      border: 0.2rem solid map.get($passwordComplexityBorderColor, 'default');
      &:not(:last-child) {
        margin-right: 0.2rem;
      }
    }
  }

  > .text {
    margin-top: 0.2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 1.7rem;
    display: flex;
    align-items: flex-end;
    text-align: right;
    letter-spacing: 0.06em;
  }

  &.error-simple-1,
  &.error-simple-2 {
    > .lines .line-1 {
      border-color: map.get($passwordComplexityBorderColor, 'error');
    }
    > .text {
      color: $red;
    }
  }

  &.error-simple-2 {
    > .lines .line-2 {
      border-color: map.get($passwordComplexityBorderColor, 'error');
    }
  }

  &.fair {
    > .lines {
      > .line-1,
      .line-2,
      .line-3 {
        border-color: map.get($passwordComplexityBorderColor, 'fair');
      }
    }
    > .text {
      color: $orange;
    }
  }

  &.good,
  &.excellent {
    > .lines {
      > .line-1,
      .line-2,
      .line-3,
      .line-4 {
        border-color: map.get($passwordComplexityBorderColor, 'good');
      }
    }
    > .text {
      color: $sea-green;
    }
  }

  &.excellent {
    > .lines .line-5 {
      border-color: map.get($passwordComplexityBorderColor, 'excellent');
    }
  }
}
