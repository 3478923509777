@use 'sass:map';
@import '../../styles/styles.scss';
@import '../../styles/variables.scss';
.special-mobile-input {
  max-width: 100%;
  min-width: 100%;
  border: 1px solid $mobileInputBorderColor !important;
  border-radius: 0.9rem !important;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem !important;
  line-height: 2.1rem !important;
  letter-spacing: 0.04em !important;
  color: $black !important;
  position: absolute;
  height: 5rem !important;
  width: unset !important;

  .special-mobile-input::after {
    content: none;
  }
}

.special-mobile-button {
  background-color: $white !important;
  border-right: 0px $mobileInputBorderColor !important;
  border-radius: 0.9rem 0 0 0.9rem !important;

  .arrow {
    display: none;
  }

  .selected-flag {
    &:hover,
    &:focus {
      background-color: transparent !important;
    }

    .flag {
      margin-left: 0.3rem;
      background-image: url('../../assets/svg/flag/AU.svg');

      &.au {
        background-image: url('../../assets/svg/flag/AU.svg');
        height: 21px;
        width: 30px;
        top: 38% !important;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  &.flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }
}

.special-mobile-placeholder {
  position: absolute;
  left: 9.2rem;
  top: 1.7rem;
  opacity: 0.9 !important;
  font-size: 1.6rem !important;
  line-height: 2.1rem !important;
  font-style: normal;
  font-weight: normal;
  color: $mobileInputPlaceholderColor;
}

// custom styles for Mobile Input
.form-control.special-mobile-input {
  border: 0.1rem solid $line-2 !important;
  &:focus {
    border: 0.2rem solid $black !important;
  }
}

.form-control.special-mobile-input.error {
  border: 0.1rem solid $red !important;

  &:focus {
    border: 0.2rem solid $red !important;
  }
}

.flag-dropdown.special-mobile-button {
  margin: 0.2rem;
  outline: none !important;
  border: none !important;
}
