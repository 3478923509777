@import '../../../styles//variables';
@import '../../../styles/breakpoints';

.activationConfirmPin {
  background-color: $sea-green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .nonTopBack {
    display: none;
    z-index: 1;
  }

  .pinLoading {
    position: absolute;
    left: 50%;
    top: 1%;
    transform: translate(-50%, 0);
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    padding: 1rem;
  }
  .confirmPinCardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
    position: relative;
    top: -3rem;
  }
  .scallop {
    position: relative;
    top: 0rem;
  }

  .pinSection {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    position: relative;
    top: -3.5rem;
    margin-bottom: -3.5rem;
    .pinSectionWrapper {
      margin: 0 auto;
      padding: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .pinInput {
        padding-bottom: 12.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 2.9rem;
        line-height: 4.1rem;
        font-family: 'Agrandir-TextBold';
        text-align: center;
        margin-bottom: 6rem;
      }

      .pinErrorText {
        margin-top: 1.5rem;
        margin-left: 1.5rem;
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .activateButton {
        width: 100%;
        margin-top: 6rem;
        margin-bottom: 3rem;
      }

      .pinWarning {
        font-size: 1.3rem;
        line-height: 1.7rem;
        text-align: center;
        margin-top: auto;
      }
    }
  }
  @include respondTo(tablet) {
    .nonTopBack {
      display: inline-block;
      padding: 2.8rem 2.2rem;
      position: absolute;
      top: 6rem;
      padding-left: 3.2rem;
    }

    .confirmPinCardWrapper {
      margin-top: 8rem;
      margin-bottom: 2.8rem;
      top: 0rem;
    }
    .scallop {
      position: relative;
      top: 55px;
    }
    .pinSection {
      margin-top: 2rem;
      top: 0rem;
      margin-bottom: 0rem;
      .pinSectionWrapper {
        max-width: 45rem;
        .activateButton {
          margin-top: 4rem;
          max-width: 31rem;
        }
      }
    }
  }
}
