@use 'sass:map';
@import '../../../styles/variables';

.pinInputGrid {
  display: flex;
  .singleInput {
    outline: none;
    box-sizing: border-box;
    padding: 0rem;
    margin: 0 0.25rem;
    text-align: center;
    border: map.get($border, 'default');
    border-radius: map.get($border-radius, 'rounded');
    font-size: 1.6rem;

    &:focus {
      border: 0.15rem solid $black;
      &::placeholder,
      &::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
  .small {
    width: 4.3rem;
    height: 5rem;
  }

  .big {
    width: 7rem;
    height: 8rem;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin: 0 0.5rem;
  }

  .error {
    border: map.get($border, 'error');
  }

  .success {
    border: map.get($border, 'success');
  }
}

.hidden-pip-small {
  position: absolute; 
  font-size: 24px;
  left: 0;
  top: 0;
  width: 4.8rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.hidden-pip-big {
  position: absolute;
  font-size: 36px;
  left: 0;
  top: 0;
  width: 7.5rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center; 
}