@import "../../styles/breakpoints";

.how-it-works-subtitle {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: min(1rem, 10vw);

  @include respondTo(desktop) {
    margin-top: 4rem;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-size: min(17rem, 15vw);
  }

  > .subtitle {
    width: 100%;
  }

  > .right-subtitle {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    cursor: pointer;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respondTo(tablet) {
      margin-top: 0rem;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    > * {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &:not(:last-child) {
        margin-right: 4rem;
      }
      
      @include respondTo(tablet) {
        transform: scale(1);
      }

      > span {
        font-size: 2rem;
        font-weight: bold;
        margin-right: 1rem;

        @include respondTo(tablet) {
          font-size: 2.4rem;
          margin-right: 2rem;
        }
      }

      > img {
        width: 3rem;
        @include respondTo(tablet) {
          width: 5rem;
        }
      }
    }
  }
}

.how-it-works {
  margin-bottom: 14rem;

  > .hit-section {
    margin-top: 6rem;

    @include respondTo(tablet) {
      margin-top: 10rem;
    }

    > .hit-header {
      font-family: "Kobe-Bold";
      font-weight: bold;
      font-size: min(6rem, 13vw);
      line-height: 85%;
      text-align: center;
      position: relative;
      transition: all ease 0.3s;

      @include respondTo(tablet) {
        font-size: min(7rem, 15vw);
      }

      @include respondTo(desktop) {
        font-size: min(9rem, 15vw);
      }

      &.giving > img {
        position: absolute;
        left: 0;
        top: -4rem;
        height: min(7rem, 15vw);

        @include respondTo(tablet) {
          left: 15%;
          top: -5rem;
          height: min(9rem, 15vw);
        }

        @include respondTo(desktop) {
          left: 15%;
          top: -5rem;
          height: min(12rem, 15vw);
        }
      }

      &.receiving > img {
        position: absolute;
        right: -1rem;
        top: 10rem;
        height: min(7rem, 15vw);
        transform: rotate(-90deg);

        @include respondTo(tablet) {
          right: 10%;
          top: -4rem;
          height: min(9rem, 15vw);
          transform: rotate(0deg);
        }

        @include respondTo(desktop) {
          right: 15%;
          top: -4rem;
          height: min(12rem, 15vw);
          transform: rotate(0deg);
        }
      }
    }

    > .hit-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0rem -2.5rem 0rem;

      @include respondTo(tablet) {
        margin: 7rem -2.5rem 0rem;
      }


      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin-top: 5rem;
        max-width: 100%;

        @include respondTo(tablet) {
          width: 50%;
        }

        @include respondTo(desktop) {
          width: 25%;
        }

        > .hit-image {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 4rem;
          height: 14rem;

          @include respondTo(mobile) {
            height: 21.8rem;
          }

          > img {
            height: 100%;
          }

          &.heart {
            width: 100%;
            padding: 0 20%;
          }
        }

        > .hit-title {
          text-align: center;
          font-size: 2.9rem;
          font-weight: bold;
          margin-bottom: 3rem;
          font-family: "Agrandir-TextBold";

          @include respondTo(tablet) {
            height: 7.8rem;
          }
        }

        > .hit-description {
          text-align: center;
          font-size: 2rem;
          line-height: 140%;
        }
      }
    }
  }
}
