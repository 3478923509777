@import '../../styles/_variables.scss';
@import '../../styles/styles.scss';
@import '../../styles//breakpoints';

.Modal {
  position: absolute;
  background: #ffffff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
  padding: 4rem;
  top: 0;
  bottom: 0;
  margin: auto;
  width: unset;
  height: unset;
  border: unset;
  box-shadow: unset;
  border-radius: unset;
  overflow: scroll;
  padding-bottom: 15rem;
  @include respondTo(mobile) {
    border: 2px solid #000000;
    box-sizing: border-box;
    box-shadow: 8px 8px 0px #000000;
    width: 48rem;
    height: fit-content;
    border-radius: 18px;
    padding: 5.5rem;
    max-height: 78.4vh;
    // hide scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
  z-index: 5000;
}

.ModalTitle {
  font-family: Agrandir;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #000000;
  letter-spacing: 0.04em;
  margin-bottom: 1rem;

  @include respondTo(tablet) {
    margin-top: 0;
  }
}

.ModalSubTitle {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 160%;
  letter-spacing: 0.04em;
  margin-bottom: 2rem;
}

.ModalInput {
  margin-bottom: 2rem;
}
