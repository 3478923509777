@use 'sass:map';
@import '../../styles/styles.scss';
@import '../../styles/breakpoints';

.modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal {
  width: 49rem;
  border: 2px solid $black;

  border-radius: map.get($border-radius, 'rounded');
  box-shadow: 8px 8px 0px $black;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px;
  background-color: white;
  
  @include respondTo(desktop) {
    margin: 3rem !important;
    padding: 5.5rem;
  }
}

.modal-title {
  font-family: Agrandir;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 140%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.modal-text {
  font-family: Agrandir;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 0.04rem;
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
}