@import '../../styles/breakpoints';

.dynamicCard {
  position: relative;
  display: inline-block;

  .topStars {
    position: absolute;
    top: -25px;
    right: 100%;
    z-index: 3;
    height: 4.4rem;
    width: 4.4rem;
  }

  .card {
    max-width: 47.5rem;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    aspect-ratio: 1.58;
    position: relative;
    min-width: 20rem;

    img {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      object-fit: cover;
    }
  }

  .bottomStars {
    position: absolute;
    bottom: -25px;
    left: 100%;
    z-index: 3;
    height: 4.4rem;
    width: 4.4rem;
  }
  @include respondTo(tablet) {
    .topStars {
      position: absolute;
      top: -25px;
      right: 100%;
      z-index: 3;
      height: 5.9rem;
      width: 5.9rem;
    }
    .card {
      max-width: 47.5rem;
      width: 100%;
      height: 100%;
      border-radius: 1.5rem;
      aspect-ratio: 1.58;
      position: relative;
      min-width: 20rem;

      img {
        height: 100%;
        width: 100%;
        border-radius: 1.1rem;
        object-fit: cover;
      }
    }

    .bottomStars {
      position: absolute;
      bottom: -25px;
      left: 100%;
      z-index: 3;
      height: 5.9rem;
      width: 5.9rem;
    }
  }
}
