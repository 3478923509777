@use 'sass:map';
@import '../../styles/styles.scss';

.search-wrapper {
  border-radius: map.get($border-radius, 'rounded');
  border: map.get($border, 'default');
  transition: height 0.3s ease;
  overflow: hidden;

  &:focus-within {
    border: map.get($border, 'selected');
  }
}

.special-search {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  color: $black;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5rem;
  height: 6rem;

  .search-field {
    width: 100%;
    display: flex;
    padding: 1.2rem 1rem;
    outline: none;
    border: none;
    border-radius: map.get($border-radius, 'rounded');
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: bold;

    @include respondTo(mobile) {
      font-size: 1.5rem;
      padding: 1.2rem 1.5rem;
    }
  }

  .icon {
    height: 2.7rem;
    width: 2.7rem;

    &.search-icon {
      background-image: url('../../assets/svg/SearchIcon.svg');
      background-position: center;
      background-repeat: no-repeat;
    }

    &.search-clear {
      cursor: pointer;
      background-color: unset;
      border: none;
      background-image: url('../../assets/svg/XMark.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .search-match {
    top: 100%;
    left: 0;
    position: absolute;
    padding: 0;
    margin: 0 -0.2rem;
    width: calc(100% + 0.4rem);
    background: $white;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-width: 0 !important;
    list-style-type: none;

    li {
      height: 4.4rem;

      > span,
      > button {
        height: 100%;
        background: none;
        border: none;
        width: 100%;
        padding: 1.2rem 5.5rem;
        font-weight: bold;
        font-size: 1.5rem;
        text-transform: uppercase;
        text-align: left;
      }

      > button {
        &:hover,
        &:focus {
          background: $options-background-hover;
          cursor: pointer;
          outline: none;
        }
      }

      > span {
        color: $mobileInputPlaceholderColor;

        &::after {
          content: "";
          animation: loader 1s linear infinite;
        }
      }
    }
  }
}

@keyframes loader {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: "";
  }
}