@import '../../styles/styles.scss';
@import '../../styles/variables';

.scroller-container {
  margin: 5rem 0;
  padding: 1rem;
  background: $black;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  height: 6rem;
  * {
    color: $white;
    font-size: 4rem;
    text-transform: uppercase;
    font-family: 'Kobe-bold';
    margin: 0;
    padding: 0;
  }

  .marquee {
    display: flex;
    gap: 1rem;
  }

  &.inverse {
    background: $white;
    border: 1px solid $black;
    * {
      color: $black;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.scroller-outer {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}
