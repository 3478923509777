@use 'sass:map';
@import '../../styles/styles.scss';

.special-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1.9rem;
  width: max-content;
  letter-spacing: 0.04em;
  cursor: pointer;
  text-decoration: none;

  &.side-menu > span {
    font-weight: bold;
  }

  > * {
    font-weight: 700;

    &:hover {
      color: $sea-green !important;
    }
  }

  &.custom-hover {
    span:hover {
      color: $white !important;
    }
  }
  &.active:not(.no-highlight-color) > * {
    color: $sea-green !important;
  }

  &.black > * {
    color: $black;
  }

  &.white > * {
    color: $white;
  }

  > .icon {
    background-position: center;
    background-repeat: no-repeat;
  }

  > .left-heart-icon {
    margin-right: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/Heart.svg');
  }

  > .left-heart-purple-icon {
    margin-right: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/HeartPurple.svg');
  }

  > .right-heart-icon {
    margin-left: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/Heart.svg');
  }

  > .right-heart-purple-icon {
    margin-left: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/HeartPurple.svg');
  }

  > .left-flower-icon {
    margin-right: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/Flower.svg');
  }

  > .right-flower-icon {
    margin-left: 1.2rem;
    height: 2.5rem;
    width: 2.3rem;
    background-image: url('../../assets/svg/Flower.svg');
  }

  &.mobile {
    height: 6rem;
    font-size: 6rem;
    padding: 0rem;
    border: transparent;
    width: 100%;
    height: 100%;
    line-height: 85%;
    text-align: initial;
    justify-content: start;

    > * {
      font-family: 'Kobe-Bold';
      font-weight: bold;
    }

    > .left-heart-icon {
      margin-left: 1.8rem;
      height: 3.1rem;
      width: 3.4rem;
      background-image: url('../../assets/svg/HeartBig.svg');
      margin-bottom: 1rem;
    }
  }
}
