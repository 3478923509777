.special-logo {
  width: max-content;
  display: flex;

  > * {
    background-position: center;
    background-repeat: no-repeat;
    height: 6.1rem;
    width: 21.3rem;

    &.header {
      height: 3.4rem;
      width: 11.8rem;
    }
  }

  > .white {
    background-image: url('../../assets/svg/logo/White.svg');

    &.header {
      background-image: url('../../assets/svg/logo/header/White.svg');
    }
  }

  > .black {
    background-image: url('../../assets/svg/logo/Black.svg');

    &.header {
      background-image: url('../../assets/svg/logo/header/Black.svg');
    }
  }

  > .fullColorBlack {
    background-image: url('../../assets/svg/logo/FullColorBlack.svg');

    &.header {
      background-image: url('../../assets/svg/logo/header/FullColorBlack.svg');
    }
  }

  > .fullColorWhite {
    background-image: url('../../assets/svg/logo/FullColorWhite.svg');
    background-size: contain;
  }
}
