@import '../../../styles/breakpoints';

.layout-wrapper {
  position: relative;
  padding: 2rem 0 0 0;

  @include respondTo(tablet) {
    margin-top: 5rem;
    padding: 9.7rem 0 0 9.5rem;
  }

  p {
    &.text {
      font-size: 2rem;
      line-height: 2.8rem;
      color: white;
      margin: 0 0 6rem 0;
      letter-spacing: 0.04em;

      @include respondTo(tablet) {
        margin: 2rem 0 6rem 0;
      }

      @include respondTo(desktop) {
        margin: 0rem 0 10rem 0;
      }
    }
  }

  img {
    position: absolute;
    bottom: -17vw;
    left: 0;
    z-index: 10;
    width: 20vw;

    @include respondTo(tablet) {
      top: 0;
      left: 0;
      width: 12.5rem;
    }
  }
}
