@import '../../../styles/breakpoints';
@import '../../../styles/variables';

.purchase-customise {
  .select-text {
    font-size: 1.25rem;
    line-height: 1.9rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    font-family: 'Agrandir-TextBold';
    margin-bottom: 2rem;
  }
  .purchase-customise-divider {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
  }
  .selectCelebration {
    margin-bottom: 4rem;
  }

  .designContainer.celebrationContainer {
    justify-content: space-evenly;
  }
  .designContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .design {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      margin-bottom: 20px;
      border: 1px solid $black;
      border: 1px solid rgba($color: $black, $alpha: 0.1);
      cursor: pointer;
    }

    .selected {
      position: relative;
      right: -25px;
      bottom: -20px;
    }

    .celebration {
      flex: 1;
      border-radius: 9px;
      height: 70px;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      -webkit-box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid $line-2;
      max-width: 10.8rem;
    }
    .celebrationSelected {
      z-index: 1;
      position: absolute;
      right: 3px;
      bottom: 3px;
    }

    .animationImage {
      height: 100%;
      width: 100%;
    }
  }

  @include respondTo(tablet) {
    .celebrationContainer {
      justify-content: flex-start;
    }
  }
}
